// src/components/dashboard/modals/DeleteAccountModal.jsx
import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';

const DeleteAccountModal = ({ isOpen, onClose, onDeleteAccount }) => {
    const [deleteError, setDeleteError] = useState('');
    const [deleteSuccess, setDeleteSuccess] = useState('');

    const handleDelete = async () => {
        setDeleteError('');
        setDeleteSuccess('');

        try {
            await onDeleteAccount();
            setDeleteSuccess('Twoje konto zostało pomyślnie usunięte.');
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                setDeleteError('Nieprawidłowe hasło.');
            } else {
                setDeleteError(error.message || 'Nie udało się usunąć konta.');
            }
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-gray-900">Usuń konto</h2>
            </ModalHeader>
            <ModalBody>
                <p className="text-sm text-gray-600">
                    Czy na pewno chcesz usunąć swoje konto? Ta akcja jest nieodwracalna i spowoduje usunięcie wszystkich Twoich danych.
                </p>
                {deleteError && <div className="text-red-500 text-sm mt-2">{deleteError}</div>}
                {deleteSuccess && <div className="text-green-500 text-sm mt-2">{deleteSuccess}</div>}
            </ModalBody>
            <ModalFooter className="flex justify-between">
                <Button variant="outline" onClick={onClose}>
                    Anuluj
                </Button>
                <Button variant="destructive" onClick={handleDelete}>
                    Usuń konto
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteAccountModal;
