// components/ui/button.js
import React from 'react';
import clsx from 'clsx';


export function Button({ children, variant = 'primary', size = 'md', className = '', ...props }) {
    const baseStyle = 'inline-flex items-center justify-center font-semibold rounded-lg focus:outline-none transition-all duration-300 ease-in-out';
    const variantStyles = {
        primary: '  ',
        outline: 'border  text-gray-700   focus:ring-gray-200',
        secondary: '  text-white  focus:ring-gray-500 shadow-md hover:shadow-lg',
    };
    const sizeStyles = {
        sm: 'px-3 py-1.5 text-sm',
        md: 'px-3 py-1.5 text-sm sm:px-4 sm:py-2 sm::text-md',
        lg: 'px-5 py-3 text-lg',
    };

    return (
        <button
            className={clsx(baseStyle, variantStyles[variant], sizeStyles[size], className)}
            {...props}
        >
            {children}
        </button>
    );
}