// src/pages/Dashboard.jsx
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { auth } from "../firebase";
import { useNavigate } from 'react-router-dom';
import {
    CreditCard,
    BarChart2,
    GraduationCap,
    User,
} from 'lucide-react';
import { Button } from '../components/ui/button';
import ChangePasswordModal from '../components/dashboard/modals/ChangePasswordModal';
import DeleteAccountModal from '../components/dashboard/modals/DeleteAccountModal';
import EmailVerificationModal from '../components/dashboard/modals/EmailVerificationModal';
import DashboardTile from '../components/dashboard/DashboardTile';
import Payments from '../components/dashboard/Payments/Payments';
import Account from '../components/dashboard/Account';
import Statistics from '../components/dashboard/Statistics';
import Quizzes from '../components/dashboard/Quizzes';
import QuizDetails from '../components/dashboard/QuizDetails';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { sendEmailVerification } from 'firebase/auth';
import { Progress } from '../components/ui/progress';
import TutorialOverlay from '../components/dashboard/TutorialOverlay';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card"
import { logEvent } from '../analytics';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
    const [userData, setUserData] = useState(null);
    const [quizSummaries, setQuizSummaries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('dashboard');
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [showVerifyPopup, setShowVerifyPopup] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState([]);

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

    const [isTutorialCompleted, setIsTutorialCompleted] = useState(false); // Stan dla tutoriala
    const [tutorialStep, setTutorialStep] = useState(() => {
        const savedTutorialStep = localStorage.getItem('tutorialStep');
        return savedTutorialStep !== null ? parseInt(savedTutorialStep, 10) : 0;
    });

    const [isTutorialOpen, setIsTutorialOpen] = useState(() => {
        const savedIsTutorialOpen = localStorage.getItem('isTutorialOpen');
        return savedIsTutorialOpen !== null ? savedIsTutorialOpen === 'true' : false;
    });

    const handleTutorialClose = () => {
        setIsTutorialOpen(false);
        localStorage.setItem('tutorialSeen', 'true');

        // Jeśli email nie jest zweryfikowany, pokaż popup po zamknięciu tutoriala
        if (!user?.emailVerified) {
            setShowVerifyPopup(true);
        }
    };

    const navigate = useNavigate();
    const dashboardRefs = {
        payments: useRef(null),
        quizzes: useRef(null),
        // statistics: useRef(null),
        account: useRef(null),
        // Dodaj kolejne referencje, jeśli potrzebujesz
    };


    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            setUser(currentUser);

            if (currentUser) {
                // Pobierz z localStorage informację, czy tutorial był już widziany
                const tutorialSeen = localStorage.getItem('tutorialSeen');

                if (!tutorialSeen) {
                    // Jeśli tutorial nie był widziany, pokaż tutorial
                    setIsTutorialOpen(true);
                }

                // Ustawienie zmiennej isVerified na podstawie stanu emailVerified
                if (currentUser.emailVerified) {
                    setIsVerified(true);
                    setShowVerifyPopup(false); // Ukryj popup, jeśli email jest zweryfikowany
                } else {
                    setIsVerified(false);
                    setShowVerifyPopup(true); // Pokaż popup, jeśli email nie jest zweryfikowany
                }


                // Pobierz dane użytkownika
                fetchUserData(currentUser);
            } else {
                setLoading(false);
            }
        });

        // Sprawdź, czy tutorial został już ukończony
        const tutorialCompleted = localStorage.getItem('tutorialCompleted');
        setIsTutorialCompleted(tutorialCompleted === 'true');

        // Odczytaj tutorialStep i isTutorialOpen z localStorage
        const savedTutorialStep = localStorage.getItem('tutorialStep');
        if (savedTutorialStep !== null) {
            setTutorialStep(parseInt(savedTutorialStep, 10));
        }
        const savedIsTutorialOpen = localStorage.getItem('isTutorialOpen');
        if (savedIsTutorialOpen !== null) {
            setIsTutorialOpen(savedIsTutorialOpen === 'true');
        }

        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        localStorage.setItem('tutorialStep', tutorialStep);
    }, [tutorialStep]);

    useEffect(() => {
        localStorage.setItem('isTutorialOpen', isTutorialOpen);
    }, [isTutorialOpen]);


    const handleTutorialComplete = () => {
        setIsTutorialCompleted(true);
        setIsTutorialOpen(false);
        localStorage.setItem('tutorialCompleted', 'true');
        localStorage.setItem('tutorialSeen', 'true');
    };


    const fetchUserData = async (currentUser) => {
        try {
            // Pobierz token ID użytkownika
            const idToken = await currentUser.getIdToken();

            // Wyślij żądanie do backendu z tokenem w nagłówkach
            const response = await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/getUserDataWithQuizSummaries',
                {}, // Puste ciało żądania
                {
                    headers: {
                        Authorization: `Bearer ${idToken}`, // Przekaż token uwierzytelniający
                    },
                }
            );

            // Przetwarzanie odpowiedzi z backendu
            const userDataResponse = response.data.userData;
            const quizSummariesResponse = response.data.quizSummaries;
            const transactionsResponse = response.data.transactions; // Pobierz transakcje

            // Przetwórz dane użytkownika
            const processedUserData = {
                userName: userDataResponse.userName || "Nieznany użytkownik",
                subscriptionStatus: userDataResponse.subscriptionStatus || "Brak subskrypcji",
                subscriptionEndDate: userDataResponse.subscriptionEndDate || "N/A",
                requestCount: userDataResponse.requestCount || 0,
                requestLimit: userDataResponse.requestLimit || 0,
                email: userDataResponse.email,
            };

            // Przetwórz podsumowania quizów
            const processedQuizSummaries = quizSummariesResponse.map((quiz) => ({
                id: quiz.id,
                courseName: quiz.courseName || "Brak nazwy kursu",
                data: {
                    Ocena: quiz.data.Ocena || "Brak oceny",
                    Rozpoczęto: quiz.data.Rozpoczęto || "Brak danych",
                    Ukończono: quiz.data.Ukończono || "Brak danych",
                    Stan: quiz.data.Stan || "Brak danych",
                    "Wykorzystany czas": quiz.data["Wykorzystany czas"] || "Brak danych",
                    testName: quiz.testName || "Brak nazwy testu",
                    timestamp: quiz.timestamp || "Brak danych",
                    universityUrl: quiz.universityUrl || "",
                    userEmail: quiz.data.userEmail || "",
                    questions: quiz.data.questions || [],
                },
            }));

            // Przetwórz transakcje
            const processedTransactions = transactionsResponse.map((transaction) => ({
                id: transaction.id,
                date: transaction.date && transaction.date._seconds
                    ? new Date(transaction.date._seconds * 1000).toLocaleDateString("pl-PL", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    })
                    : "Brak daty",
                description: transaction.description || "Brak opisu",
                amount: transaction.amount || "Brak kwoty",
                status: transaction.status || "Nieznany status",
            }));

            // Ustaw dane w stanie
            setUserData(processedUserData);
            setQuizSummaries(processedQuizSummaries);
            setTransactions(processedTransactions); // Dodaj transakcje do stanu
        } catch (error) {
            console.error("Błąd podczas pobierania danych użytkownika:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleResendVerification = async () => {
        try {
            const user = auth.currentUser;
            if (user && !user.emailVerified) {
                const idToken = await user.getIdToken();

                const response = await axios.post(
                    'https://us-central1-quizonator-a4513.cloudfunctions.net/api/send-verification-email',
                    { email: user.email },
                    {
                        headers: {
                            'Authorization': `Bearer ${idToken}`,
                        },
                    }
                );

                if (response.status === 200) {
                    // Sukces, nie musisz nic więcej robić tutaj, komunikat zostanie wyświetlony w modalu
                } else {
                    throw new Error('Błąd podczas wysyłania emaila weryfikacyjnego.');
                }
            } else if (user && user.emailVerified) {
                throw new Error('Twój adres email jest już zweryfikowany.');
            } else {
                throw new Error('Nie jesteś zalogowany.');
            }
        } catch (error) {
            console.error('Błąd podczas wysyłania emaila weryfikacyjnego:', error);
            throw error; // Przekaż błąd do modalu
        }
    };


    const handleChangePassword = async (currentPassword, newPassword) => {
        try {
            const user = auth.currentUser;

            if (!user) {
                throw new Error("Nie znaleziono zalogowanego użytkownika.");
            }

            // Pobierz token ID użytkownika
            const idToken = await user.getIdToken();


            // Wyślij żądanie do backendu
            const response = await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/change-password',
                { newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${idToken}` // Przekaż token ID w nagłówkach
                    }
                }
            );

            alert(response.data.message); // Komunikat sukcesu z backendu
        } catch (error) {
            console.error("Błąd podczas zmiany hasła:", error);
            alert(error.response?.data?.error || "Nie udało się zmienić hasła.");
        }
    };




    const handleDeleteAccount = async () => {
        try {
            const password = prompt('Aby usunąć konto, proszę wprowadź swoje hasło:');
            if (!password) {
                throw new Error('Hasło jest wymagane do usunięcia konta.');
            }

            const user = auth.currentUser;
            if (user) {
                const credential = EmailAuthProvider.credential(user.email, password);
                await reauthenticateWithCredential(user, credential);
                await user.delete();
                navigate('/register');
            } else {
                throw new Error('Nie udało się znaleźć zalogowanego użytkownika.');
            }
        } catch (error) {
            console.error('Błąd podczas usuwania konta:', error);
            throw error;
        }
    };
    const renderSkeletonLoader = () => (
        <div className="space-y-6">
            {/* Nagłówek z nazwą użytkownika */}
            <div className="flex items-center space-x-4">
                <div className="space-y-2">
                    <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 w-60 bg-gray-200 rounded animate-pulse"></div>
                </div>
            </div>

            {/* Skeletony dla kafelków */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div
                        key={index}
                        className="h-24 bg-gray-200 rounded-lg animate-pulse"
                    ></div>
                ))}
            </div>
        </div>
    );

    const renderDashboard = () => (
        <>
            <Helmet>
                <title>Panel użytkownika - Quizonator</title>
                <meta
                    name="description"
                    content="Zarządzaj swoim kontem, przeglądaj statystyki i korzystaj z funkcji Quizonatora."
                />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <div className="space-y-6">
                {/* Welcome Header */}
                <div className="bg-white p-4 sm:p-6 rounded-lg border border-gray-300 text-center sm:text-left">
                    <h1 className="text-2xl sm:text-3xl font-bold text-gray-800">
                        Witaj, {userData?.userName || "Użytkowniku"}!
                    </h1>
                    <p className="text-sm sm:text-base text-gray-600 mt-2">
                        Cieszymy się, że jesteś z nami! Poniżej znajdziesz swoje dane oraz funkcje aplikacji.
                    </p>
                </div>

                {/* Statistics Section */}
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    <Card className="border border-gray-300 p-4 sm:p-6">
                        <CardHeader className="flex flex-row items-center justify-between">
                            <CardTitle className="text-sm sm:text-base font-medium">Wykorzystane zapytania</CardTitle>
                            <CreditCard className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-2">
                                <div className="text-lg sm:text-2xl font-bold">
                                    {userData?.requestCount} / {userData?.requestLimit}
                                </div>
                                <Progress
                                    value={(userData?.requestCount / (userData?.requestLimit || 1)) * 100}
                                />
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="border border-gray-300 p-4 sm:p-6">
                        <CardHeader className="flex flex-row items-center justify-between">
                            <CardTitle className="text-sm sm:text-base font-medium">Rozwiązane quizy</CardTitle>
                            <GraduationCap className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-lg sm:text-2xl font-bold">{quizSummaries?.length || 0}</div>
                            <p className="text-xs sm:text-sm text-muted-foreground">
                                {quizSummaries?.length
                                    ? `Ostatni quiz: ${quizSummaries[quizSummaries.length - 1]?.data.Ukończono}`
                                    : "Rozpocznij naukę już teraz"}
                            </p>
                        </CardContent>
                    </Card>

                    {/* <Card className='border border-gray-300 p-4 sm:p-6'>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0">
                        <CardTitle className="text-sm sm:text-base font-medium">Średnia poprawność</CardTitle>
                        <BarChart2 className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                    </CardHeader>
                    <CardContent>
                        <div className="text-lg sm:text-2xl font-bold">0%</div>
                        <p className="text-xs sm:text-sm text-muted-foreground">
                            Rozwiąż quiz aby zobaczyć statystyki
                        </p>
                    </CardContent>
                </Card> */}
                    <Card className='border border-gray-300 p-4 sm:p-6'>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0">
                            <CardTitle className="text-sm sm:text-base font-medium">Status subskrypcji</CardTitle>
                            <User className="h-5 w-5 sm:h-6 sm:w-6 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-lg sm:text-2xl font-bold">
                                {new Date() < new Date(userData?.subscriptionEndDate._seconds * 1000 + userData?.subscriptionEndDate._nanoseconds / 1000000) ? "Aktywna" : "Nieaktywna"}
                            </div>
                            <p className="text-xs sm:text-sm text-muted-foreground">
                                Ważna do: {userData?.subscriptionEndDate ? new Date(userData?.subscriptionEndDate._seconds * 1000 + userData?.subscriptionEndDate._nanoseconds / 1000000 - 86400000).toLocaleDateString() : "n/a"}
                            </p>
                        </CardContent>
                    </Card>
                </div>

                {/* Navigation Tiles */}
                <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
                    <DashboardTile
                        title="Płatności"
                        description="Zarządzaj swoją subskrypcją"
                        Icon={CreditCard}
                        onClick={() => {
                            setActiveSection('payments');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', 'Płatności');
                        }}
                        ref={dashboardRefs.payments}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />
                    <DashboardTile
                        title="Konto"
                        description="Edytuj swoje dane"
                        Icon={User}
                        onClick={() => {
                            setActiveSection('account');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', 'Konto');
                        }}
                        ref={dashboardRefs.account}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />
                    {/* <DashboardTile
                    title="Statystyki"
                    description="Sprawdź swoje postępy"
                    Icon={BarChart2}
                    onClick={() => {
                        setActiveSection('statistics');
                        window.scrollTo(0, 0);
                    }}
                    ref={dashboardRefs.statistics}
                    className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                /> */}
                    <DashboardTile
                        title="Quizy"
                        description="Przeglądaj dostępne testy"
                        Icon={GraduationCap}
                        onClick={() => {
                            setActiveSection('quizzes');
                            window.scrollTo(0, 0);
                            logEvent('Dashboard', 'Click', 'Quizy');

                        }}
                        ref={dashboardRefs.quizzes}
                        className="p-4 sm:p-6 hover:shadow-lg transition-shadow rounded-lg"
                    />
                </div>

                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className="text-lg sm:text-2xl font-semibold">Potrzebujesz pomocy?</CardTitle>
                        <CardDescription className="text-sm sm:text-base">
                            Nie wiesz, jak korzystać z aplikacji? Rozpocznij interaktywny tutorial!
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Button onClick={() => {
                            setIsTutorialOpen(true);
                        }} className="mt-4 bg-black text-white">
                            Uruchom tutorial
                        </Button>

                    </CardContent>
                </Card>
            </div>
        </>
    );

    const renderPayments = () => (
        <Payments userData={userData} transactions={transactions} onBack={() => setActiveSection('dashboard')} />
    );

    const renderAccount = () => (
        <Account
            userData={userData}
            onBack={() => setActiveSection('dashboard')}
            onOpenChangePassword={() => setShowChangePasswordModal(true)}
            onOpenDeleteAccount={() => setShowDeleteAccountModal(true)}
        />
    );

    const renderStatistics = () => (
        <Statistics userData={userData} onBack={() => setActiveSection('dashboard')} />
    );

    const renderQuizzes = () => (
        <Quizzes
            quizSummaries={quizSummaries}
            onBack={() => setActiveSection('dashboard')}
            onSelectQuiz={(quiz) => setSelectedQuiz(quiz)}
        />
    );

    const renderQuizDetails = () => (
        <QuizDetails
            quiz={selectedQuiz}
            onBack={() => setSelectedQuiz(null)}
        />
    );

    if (loading) {
        return (
            <div className="min-h-screen bg-background p-8">
                <div className="max-w-6xl mx-auto">
                    {renderSkeletonLoader()}
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-white p-8">
            <div className="max-w-6xl mx-auto">

                <AnimatePresence mode="wait">
                    <motion.div
                        key={activeSection + (selectedQuiz ? 'quizDetails' : '')}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                    >
                        {activeSection === 'dashboard' && renderDashboard()}
                        {activeSection === 'payments' && renderPayments()}
                        {activeSection === 'account' && renderAccount()}
                        {activeSection === 'statistics' && renderStatistics()}
                        {activeSection === 'quizzes' &&
                            (selectedQuiz ? renderQuizDetails() : renderQuizzes())}
                    </motion.div>
                </AnimatePresence>
                {isTutorialOpen && (
                    <TutorialOverlay
                        tutorialStep={tutorialStep}
                        setTutorialStep={setTutorialStep}
                        onComplete={handleTutorialComplete}
                        onClose={handleTutorialClose} // Dodaj tę linię
                        dashboardRefs={dashboardRefs}
                        isVerified={isVerified}
                        sendVerificationEmail={handleResendVerification}
                    />
                )}

            </div>

            {/* Modal do weryfikacji emaila */}
            <AnimatePresence>
                {!isTutorialOpen && showVerifyPopup && (
                    <EmailVerificationModal
                        isOpen={showVerifyPopup}
                        onClose={() => setShowVerifyPopup(false)}
                        onResendVerification={handleResendVerification}
                    />
                )}
            </AnimatePresence>

            {/* Modal do zmiany hasła */}
            <ChangePasswordModal
                isOpen={showChangePasswordModal}
                onClose={() => setShowChangePasswordModal(false)}
                onChangePassword={handleChangePassword}
            />


            {/* Modal do usuwania konta */}
            <DeleteAccountModal
                isOpen={showDeleteAccountModal}
                onClose={() => setShowDeleteAccountModal(false)}
                onDeleteAccount={handleDeleteAccount}
            />
        </div>
    );
};

export default Dashboard;
