import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';

export function Accordion({ children, className = '', ...props }) {
    return (
        <div className={`w-full ${className}`} {...props}>
            {children}
        </div>
    );
}

export function AccordionItem({ title, children, className = '', ...props }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`border-b border-gray-200 ${className}`} {...props}>
            <AccordionTrigger onClick={toggle} isOpen={isOpen}>
                {title}
            </AccordionTrigger>
            {isOpen && <AccordionContent>{children}</AccordionContent>}
        </div>
    );
}

export function AccordionTrigger({ children, onClick, isOpen, className = '', ...props }) {
    return (
        <button
            onClick={onClick}
            className={`flex items-center justify-between w-full py-4 px-2 text-left font-medium text-gray-800 ${className}`}
            {...props}
        >
            {children}
            <ChevronDown className={`h-5 w-5 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
        </button>
    );
}

export function AccordionContent({ children, className = '', ...props }) {
    return (
        <div className={`px-2 py-2 text-gray-700 ${className}`} {...props}>
            {children}
        </div>
    );
}
