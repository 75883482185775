import React from 'react';

export function Badge({ children, className = '', color = '', ...props }) {
    const colorClasses = {
        blue: 'bg-blue-500 text-white',
        green: 'bg-green-500 text-white',
        red: 'bg-red-500 text-white',
        yellow: 'bg-yellow-500 text-black',
        gray: 'bg-gray-500 text-white',
        outline: 'border border-gray-500 text-black',
    };

    return (
        <span
            className={`inline-block px-2 py-1 rounded-full text-xs font-semibold ${colorClasses[color]} ${className}`}
            {...props}
        >
            {children}
        </span>
    );
}
