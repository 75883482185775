// src/components/dashboard/Quizzes.jsx
import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import BackButton from './BackButton';
import { ScrollArea } from "../ui/scroll-area"
import { ArrowLeft } from 'lucide-react'

const Quizzes = ({ quizSummaries, onBack, onSelectQuiz }) => (
    <div className="space-y-6">
        <div className="flex items-center justify-between">
            <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                <ArrowLeft className="h-4 w-4" />
                Powrót
            </Button>
        </div>

        <div>
            <h2 className="text-3xl font-bold tracking-tight">Quizy</h2>
            <p className="text-muted-foreground">
                Historia Twoich quizów i wyników
            </p>
        </div>

        <Card className='border !p-3 sm:p-6 border-gray-300'>
            <CardHeader>
                <CardTitle className='text-2xl font-bold'>Twoje quizy</CardTitle>
                <CardDescription>Przegląd wszystkich rozwiązanych quizów</CardDescription>
            </CardHeader>
            <CardContent className=''>
                {/* Responsywne ustawienie wysokości okna przewijania */}
                <ScrollArea className="h-[400px] sm:h-[600px] lg:h-[800px] pr-1 sm:pr-4">
                    {quizSummaries.length > 0 ? (
                        <div className="space-y-4">
                            {quizSummaries.map((quiz) => (
                                <div
                                    key={quiz.id}
                                    className="flex items-center justify-between p-2 sm:p-4 bg-muted rounded-lg border border-gray-300"
                                >
                                    <div className="flex-1 min-w-0"> {/* Dodano flex-1 i min-w-0 */}
                                        <h3 className="font-medium truncate">{quiz.data.testName}</h3>
                                        <p className="text-sm text-muted-foreground truncate">
                                            Kurs: {quiz.courseName}
                                        </p>
                                        <p className="text-sm text-muted-foreground truncate">
                                            Ocena: {quiz.data.Ocena}
                                        </p>
                                    </div>

                                    <Button
                                        variant="outline"
                                        onClick={() => onSelectQuiz(quiz)}
                                    >
                                        Szczegóły
                                    </Button>
                                </div>
                            ))}

                        </div>
                    ) : (
                        <p className="text-center py-4 text-muted-foreground">Brak quizów do wyświetlenia.</p>
                    )}
                </ScrollArea>
            </CardContent>
        </Card>

    </div>
);

export default Quizzes;
