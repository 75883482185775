// src/components/dashboard/QuizDetails.jsx
import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import BackButton from './BackButton';
import { Button } from '../ui/button';
import { Progress } from '../ui/progress';
import { Badge } from '../ui/badge';
import { ArrowLeft, Clock, Calendar, CheckCircle, XCircle } from 'lucide-react';


const QuizDetails = ({ quiz, onBack }) => {
    let score = "n/a";
    let pointsEarned = null, maxPoints = null;

    if (quiz.data.Ocena) {
        // Sprawdź, czy tekst ma strukturę zawierającą nawiasy
        const scoreMatch = quiz.data.Ocena.match(/\(([^)]+)\)/); // Wyciąga zawartość w nawiasach
        if (scoreMatch) {
            score = parseFloat(scoreMatch[1]?.replace('%)', '').replace(',', '.')) || "n/a";
        }

        function extractPoints(scoreText) {
            // Wyrażenie regularne do ekstrakcji punktów
            const pattern = /(\d+(?:[.,]\d+)?)[^\d]*(?:pkt\.?|punktów?)[^\d]*(\d+(?:[.,]\d+)?)/i;
            const match = scoreText.match(pattern);

            if (match) {
                // Zamień przecinki na kropki i sparsuj na liczby zmiennoprzecinkowe
                pointsEarned = parseFloat(match[1].replace(',', '.')) || null;
                maxPoints = parseFloat(match[2].replace(',', '.')) || null;
            } else {
                pointsEarned = null;
                maxPoints = null;
            }
        }

        extractPoints(quiz.data.Ocena);
    } else {
        pointsEarned = null;
        maxPoints = null;
    }

    return (
        <div className="space-y-6">
            {/* Powrót */}
            <div className="flex items-center justify-between">
                <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                    <ArrowLeft className="h-4 w-4" />
                    Powrót do listy quizów

                </Button>
            </div>

            {/* Nagłówek */}
            <div>
                <h2 className="text-3xl font-bold tracking-tight">{quiz.data.testName}</h2>
                <p className="text-muted-foreground">{quiz.courseName}</p>
            </div>

            {/* Wynik i szczegóły */}
            <div className="grid gap-4 md:grid-cols-2">
                {/* Wynik */}
                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className='text-xl font-bold'>Wynik</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col items-center justify-center space-y-4">
                            <div className="text-5xl font-bold">{score}%</div>
                            <Progress value={score} className="w-2/3" />
                            <p className="text-sm text-muted-foreground">{quiz.data.Ocena}</p>
                        </div>
                    </CardContent>
                </Card>

                {/* Szczegóły testu */}
                <Card className='border border-gray-300'>
                    <CardHeader>
                        <CardTitle className='text-xl font-bold'>Szczegóły testu</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <Badge color='outline'>
                                    {quiz.data.Stan}
                                </Badge>
                            </div>
                            <div className="flex items-center gap-2">
                                <Clock className="h-4 w-4 text-muted-foreground" />
                                <span>Czas: {quiz.data['Wykorzystany czas']}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Calendar className="h-4 w-4 text-muted-foreground" />
                                <span>Rozpoczęto: {quiz.data.Rozpoczęto}</span>
                            </div>
                            <div className="flex items-center gap-2">
                                <Calendar className="h-4 w-4 text-muted-foreground" />
                                <span>Ukończono: {quiz.data.Ukończono}</span>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* Podsumowanie odpowiedzi */}
            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle>Podsumowanie odpowiedzi</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <CheckCircle className="h-4 w-4 text-green-500" />
                                <span>Poprawne odpowiedzi</span>
                            </div>
                            <span className="font-bold">{pointsEarned}</span>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <XCircle className="h-4 w-4 text-red-500" />
                                <span>Niepoprawne odpowiedzi</span>
                            </div>
                            <span className="font-bold">{maxPoints - pointsEarned}</span>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Sugerowane obszary do poprawy */}
            {/* <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle>Sugerowane obszary do poprawy</CardTitle>
                </CardHeader>
                <CardContent>
                    <ul className="list-disc list-inside space-y-1">
                        <li>Rozkłady prawdopodobieństwa</li>
                        <li>Estymacja parametrów</li>
                        <li>Testy statystyczne</li>
                    </ul>
                </CardContent>
            </Card> */}
        </div>
    );
};

export default QuizDetails;