// src/components/ui/modal.jsx
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const Modal = ({ isOpen, onClose, children }) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white rounded-lg shadow-lg w-full max-w-md"
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.8 }}
                    >
                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export const ModalHeader = ({ children }) => (
    <div className="px-6 py-4 border-b">{children}</div>
);

export const ModalBody = ({ children }) => (
    <div className="px-6 py-4">{children}</div>
);

export const ModalFooter = ({ children, className }) => (
    <div className={`px-6 py-4 border-t flex ${className}`}>
        {children}
    </div>
);

export const ModalTitle = ({ children }) => (
    <h2 className="text-lg font-medium">{children}</h2>
);

export const ModalContent = ({ children }) => (
    <div>{children}</div>
);
