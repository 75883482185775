// src/components/dashboard/Statistics.jsx
import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Progress } from '../ui/progress';
import BackButton from './BackButton';

const Statistics = ({ userData, onBack }) => (
    <Card className="max-w-4xl mx-auto p-6 shadow-lg rounded-lg bg-white">
        <BackButton onClick={onBack} />

        <CardHeader>
            <CardTitle>Statystyki</CardTitle>
            <CardDescription>Przegląd Twojej aktywności</CardDescription>
        </CardHeader>
        <CardContent>
            <div className="space-y-6">
                <div>
                    <div className="flex justify-between mb-1">
                        <span className="text-sm font-medium">Wykorzystane zapytania</span>
                        <span className="text-sm font-medium">
                            {userData?.requestCount} / {userData?.requestLimit}
                        </span>
                    </div>
                    <Progress
                        value={
                            (userData?.requestCount / (userData?.requestLimit || 1)) * 100
                        }
                    />
                </div>
                <div>
                    <h3 className="text-lg font-medium">Ukończone quizy</h3>
                    <p className="text-3xl font-bold">{userData?.quizCount || 0}</p>
                </div>
            </div>
        </CardContent>
    </Card>
);

export default Statistics;
