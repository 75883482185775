import React from 'react';

const TermsOfService = () => {
    const sections = [
        {
            title: "1. Postanowienia ogólne",
            content: `1.1. Niniejszy Regulamin określa zasady korzystania z rozszerzenia "Quizonator", dostępnego dla przeglądarki Chrome.

1.2. Korzystanie z Rozszerzenia jest równoznaczne z akceptacją niniejszego Regulaminu.

1.3. Rozszerzenie służy do wspomagania nauki poprzez analizę treści pytań i prezentowanie sugerowanych odpowiedzi na podstawie odpowiedzi ChatGPT.`,
        },
        {
            title: "2. Rejestracja i konto użytkownika",
            content: `2.1. Aby korzystać z pełnej funkcjonalności Rozszerzenia, użytkownik musi zarejestrować konto, podając adres e-mail, nazwę użytkownika oraz hasło.

2.2. Każde konto jest przeznaczone wyłącznie dla jednego użytkownika. Udostępnianie konta osobom trzecim jest zabronione.

2.3. Dostawca zastrzega sobie prawo do zablokowania konta w przypadku wykrycia nadużyć lub naruszenia postanowień Regulaminu.`,
        },
        {
            title: "3. Zasady korzystania z Rozszerzenia",
            content: `3.1. Użytkownik zobowiązuje się do korzystania z Rozszerzenia w sposób zgodny z prawem, niniejszym Regulaminem oraz dobrymi obyczajami.

3.2. Zabrania się wykorzystywania Rozszerzenia w sposób mogący naruszać prawa autorskie lub inne prawa osób trzecich.

3.3. Użytkownik nie może wprowadzać do Rozszerzenia treści niezgodnych z prawem, w tym treści chronionych prawem autorskim bez odpowiedniej zgody.

3.4. Użytkownik ponosi pełną odpowiedzialność za treści przesyłane za pośrednictwem Rozszerzenia do ChatGPT.`,
        },
        {
            title: "4. Odpowiedzialność Dostawcy",
            content: `4.1. Dostawca nie ponosi odpowiedzialności za treści przesyłane przez użytkowników ani za sposób, w jaki korzystają oni z Rozszerzenia.

4.2. Dostawca nie gwarantuje poprawności ani kompletności sugerowanych odpowiedzi generowanych przez ChatGPT.

4.3. Dostawca nie ponosi odpowiedzialności za jakiekolwiek szkody wynikające z korzystania lub niemożności korzystania z Rozszerzenia.

4.4. Dostawca nie odpowiada za naruszenia praw autorskich lub innych praw osób trzecich dokonane przez użytkowników.`,
        },
        {
            title: "5. Prawa autorskie i własność intelektualna",
            content: `5.1. Wszystkie prawa do Rozszerzenia, w tym prawa autorskie, należą do Dostawcy.

5.2. Użytkownik przyjmuje do wiadomości, że treści generowane za pomocą Rozszerzenia mogą podlegać prawom autorskim osób trzecich.

5.3. Użytkownik zobowiązuje się do korzystania z treści uzyskanych za pośrednictwem Rozszerzenia w sposób zgodny z obowiązującym prawem oraz do nieupowszechniania ich bez odpowiedniej zgody.`,
        },
        {
            title: "6. Blokada konta",
            content: `6.1. Dostawca zastrzega sobie prawo do zablokowania lub usunięcia konta użytkownika w przypadku:

- Naruszenia postanowień niniejszego Regulaminu.
- Wykrycia działań mogących zagrażać bezpieczeństwu systemu lub innych użytkowników.
- Wykorzystywania Rozszerzenia w sposób niezgodny z jego przeznaczeniem.`,
        },
        {
            title: "7. Ochrona danych osobowych",
            content: `7.1. Zasady przetwarzania danych osobowych użytkowników określa Polityka Prywatności dostępna [tutaj](#).`,
        },
        {
            title: "8. Zmiany w Regulaminie",
            content: `8.1. Dostawca zastrzega sobie prawo do wprowadzania zmian w niniejszym Regulaminie.

8.2. O wszelkich zmianach użytkownicy zostaną poinformowani z odpowiednim wyprzedzeniem poprzez publikację nowej wersji Regulaminu na stronie Rozszerzenia.

8.3. Dalsze korzystanie z Rozszerzenia po wprowadzeniu zmian oznacza akceptację nowego Regulaminu.`,
        },
        {
            title: "9. Postanowienia końcowe",
            content: `9.1. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie obowiązujące przepisy prawa polskiego.

9.2. Wszelkie spory wynikłe na tle niniejszego Regulaminu będą rozstrzygane przez właściwe sądy powszechne.`,
        },
        {
            title: "10. Kontakt",
            content: `W przypadku pytań, uwag lub zgłoszeń prosimy o kontakt:

- E-mail: support@quizonator.com`,
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">Regulamin korzystania z rozszerzenia "Quizonator"</h1>
                <p className="mb-6 text-lg">Data ostatniej aktualizacji: 2.12.2024</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        <p className="whitespace-pre-line">{section.content}</p>
                    </section>
                ))}
            </div>
        </div>
    );
};

export default TermsOfService;
