import React from 'react';

const CookiePolicy = () => {
    const sections = [
        {
            title: "1. Informacje ogólne",
            content: `Niniejsza Polityka Cookies określa zasady przechowywania i dostępu do informacji na urządzeniach użytkownika za pomocą plików cookies, w związku z korzystaniem przez niego z rozszerzenia "Quizonator".`,
        },
        {
            title: "2. Definicje",
            subsections: [
                {
                    title: "2.1. Administrator",
                    content: `Podmiot zarządzający i odpowiadający za przechowywanie i dostęp do informacji na urządzeniach użytkownika: Quizonator sp. z o.o., z siedzibą w Warszawie.`,
                },
                {
                    title: "2.2. Cookies",
                    content: `Małe pliki tekstowe wysyłane przez serwer i przechowywane na urządzeniu końcowym użytkownika, które mogą być odczytywane przez system teleinformatyczny Administratora.`,
                },
                {
                    title: "2.3. Użytkownik",
                    content: `Osoba fizyczna korzystająca z rozszerzenia "Quizonator".`,
                },
            ],
        },
        {
            title: "3. Cel wykorzystania plików cookies",
            content: `Pliki cookies wykorzystywane są w następujących celach:

- Uwierzytelnianie użytkownika w serwisie i utrzymanie sesji użytkownika.
- Dostosowanie zawartości rozszerzenia do preferencji użytkownika.
- Tworzenie anonimowych statystyk, które pomagają zrozumieć, w jaki sposób użytkownicy korzystają z rozszerzenia, co umożliwia ulepszanie jego struktury i zawartości.
- Zapobieganie nadużyciom i zapewnienie bezpieczeństwa.`,
        },
        {
            title: "4. Rodzaje wykorzystywanych plików cookies",
            content: `Administrator wykorzystuje dwa rodzaje plików cookies:

- Cookies sesyjne: są przechowywane na urządzeniu użytkownika i pozostają tam do momentu zakończenia sesji danej przeglądarki. Zapisane informacje są wówczas trwale usuwane z pamięci urządzenia.
- Cookies stałe: są przechowywane na urządzeniu użytkownika i pozostają tam do momentu ich skasowania. Zakończenie sesji przeglądarki lub wyłączenie urządzenia nie powoduje ich usunięcia.`,
        },
        {
            title: "5. Zarządzanie plikami cookies",
            content: `Użytkownik może w każdej chwili zmienić ustawienia dotyczące plików cookies w swojej przeglądarce internetowej, w tym całkowicie zablokować możliwość ich zapisywania. Instrukcje dotyczące zarządzania plikami cookies dostępne są w ustawieniach przeglądarki.

Należy jednak pamiętać, że ograniczenie stosowania plików cookies może wpłynąć na niektóre funkcjonalności dostępne w rozszerzeniu.`,
        },
        {
            title: "6. Pliki cookies podmiotów trzecich",
            content: `Administrator korzysta z usług podmiotów trzecich, takich jak Google Analytics, które również mogą używać plików cookies na urządzeniach użytkownika. Służą one do zbierania anonimowych informacji statystycznych o użytkownikach. Szczegółowe informacje na temat polityki prywatności tych podmiotów dostępne są na ich stronach internetowych.`,
        },
        {
            title: "7. Zmiany w Polityce Cookies",
            content: `Administrator zastrzega sobie prawo do wprowadzania zmian w niniejszej Polityce Cookies. Wszelkie zmiany będą publikowane na tej stronie i wchodzą w życie z dniem ich opublikowania.`,
        },
        {
            title: "8. Kontakt",
            content: `W przypadku pytań lub wątpliwości dotyczących niniejszej Polityki Cookies prosimy o kontakt:

- E-mail: support@quizonator.com`,
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">Polityka Cookies dla rozszerzenia "Quizonator"</h1>
                <p className="mb-6 text-lg">Data ostatniej aktualizacji: 2.12.2024</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        {section.content && (
                            <p className="mb-4 whitespace-pre-line">{section.content}</p>
                        )}
                        {section.subsections &&
                            section.subsections.map((subsection, subIndex) => (
                                <div key={subIndex} className="mb-4">
                                    <h3 className="text-xl font-semibold mb-2">{subsection.title}</h3>
                                    <p className="whitespace-pre-line">{subsection.content}</p>
                                </div>
                            ))}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default CookiePolicy;
