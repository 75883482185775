// src/components/dashboard/Account.jsx
import React from 'react';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../ui/card';
import { Button } from '../ui/button';
import BackButton from './BackButton';
import { ArrowLeft, Edit, Trash } from 'lucide-react';

const Account = ({ userData, onBack, onOpenChangePassword, onOpenDeleteAccount }) => (
    <div className="space-y-6">
        {/* Nagłówek z przyciskiem powrotu */}
        <div className="flex items-center justify-between">
            <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                <ArrowLeft className="h-4 w-4" />
                Powrót
            </Button>
        </div>

        {/* Tytuł sekcji */}
        <div>
            <h2 className="text-3xl font-bold tracking-tight">Konto</h2>
            <p className="text-muted-foreground">Zarządzaj swoimi danymi osobowymi i ustawieniami konta</p>
        </div>

        {/* Informacje o użytkowniku */}
        <Card className="border border-gray-300">
            <CardHeader>
                <CardTitle className="text-2xl font-semibold">Dane użytkownika</CardTitle>
                <CardDescription>Podstawowe informacje o Twoim koncie</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex justify-between items-center">
                    <div>
                        <p className="font-medium text-lg">{userData?.userName || 'Jan Kowalski'}</p>
                        <p className="text-sm text-muted-foreground">{userData?.email || 'jan.kowalski@example.com'}</p>
                    </div>
                </div>
            </CardContent>
        </Card>

        {/* Zarządzanie kontem */}
        <Card className="border border-gray-300">
            <CardHeader>
                <CardTitle className="text-2xl font-semibold">Zarządzaj kontem</CardTitle>
                <CardDescription>Opcje zarządzania hasłem i usunięcia konta</CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
                <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4">
                    {/* Zmień hasło */}
                    <Button
                        variant="outline"
                        className="w-full sm:flex-1 flex items-center justify-center gap-2 text-gray-700 border-gray-300 hover:bg-gray-50"
                        onClick={onOpenChangePassword}
                    >
                        <Edit className="h-4 w-4" />
                        Zmień hasło
                    </Button>

                    {/* Usuń konto */}
                    <Button
                        variant="outline"
                        className="w-full sm:flex-1 flex items-center justify-center gap-2 text-red-600 bg-red-50 hover:bg-red-100 border-red-300"
                        onClick={onOpenDeleteAccount}
                    >
                        <Trash className="h-4 w-4" />
                        Usuń konto
                    </Button>
                </div>
            </CardContent>
        </Card>
    </div>
);

export default Account;
