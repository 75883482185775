import { Button } from "../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { CheckCircle, Sparkles, Timer, Gift } from 'lucide-react';
import { Badge } from "../components/ui/badge";
import { Link } from "react-router-dom";
import { logEvent } from '../analytics';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Pricing() {
    useEffect(() => {
        logEvent('PageView', 'Pricing', 'Odwiedzono stronę "Cennik"');
    }, []);
    return (
        <>
            <Helmet>
                <title>Cennik - Wybierz plan dla siebie | Quizonator</title>
                <meta
                    name="description"
                    content="Sprawdź nasze plany cenowe i wybierz ten, który najlepiej odpowiada Twoim potrzebom. Dołącz do Quizonatora już dziś!"
                />
            </Helmet>

            <div className="relative bg-gray-50">
                {/* Background decoration */}
                {/* <div className="absolute inset-0 bg-gradient-to-b from-[#F0F8FF] to-[#E6ECF8] -z-10" />
            <div className="absolute inset-0 bg-grid-white/10 bg-[size:20px_20px] -z-10" /> */}

                <main className="container px-4 sm:px-6 lg:px-8 py-16 md:py-20 lg:py-14 mx-auto">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-[#1B4965] mb-6">
                            Cennik
                        </h1>
                        <p className="text-lg md:text-xl text-gray-700 mb-6">
                            Wybierz nasz jedyny, kompleksowy plan i uzyskaj pełen dostęp do Quizonatora!
                        </p>
                        <div className="inline-flex items-center gap-2 bg-[#00D1A7]/10 text-[#1B4965] px-4 py-2 rounded-full">
                            <Gift className="w-5 h-5" />
                            <span className="font-medium">Specjalna promocja świąteczna!</span>
                        </div>
                    </div>

                    {/* Pricing Card */}
                    <div className="max-w-2xl mx-auto">
                        <Card className="relative overflow-hidden bg-white rounded-2xl shadow-lg hover:shadow-xl transition-all">
                            {/* Promotional Banner */}
                            <div className="absolute top-6 right-6">
                                <Badge className="bg-[#00D1A7] text-white px-4 py-1 text-sm font-medium animate-pulse">
                                    <Sparkles className="w-4 h-4 mr-1 inline " />
                                    Promocja
                                </Badge>
                            </div>

                            <CardHeader className="text-center pt-12">
                                <CardTitle className="text-3xl font-bold text-[#1B4965] mb-2">Plan Semestralny</CardTitle>
                                <CardDescription className="text-lg text-gray-600">
                                    Pełen dostęp do wszystkich funkcji <br />
                                    <span className="text-sm text-gray-500">(w ramach promocji do 1 stycznia 2025 roku)</span>
                                </CardDescription>
                            </CardHeader>

                            <CardContent className="space-y-8">
                                {/* Pricing */}
                                <div className="text-center">
                                    <div className="flex items-center justify-center gap-2 mb-2">
                                        <span className="text-2xl line-through text-gray-400">120 zł</span>
                                        <span className="text-5xl font-bold text-[#1B4965]">0 zł</span>
                                    </div>
                                    <div className="flex items-center justify-center gap-2 text-gray-600">
                                        <Timer className="w-5 h-5" />
                                        <span className="text-sm sm:text-lg">Dostęp ważny do 1 stycznia 2025 roku</span>
                                    </div>
                                </div>

                                {/* Features */}
                                <div className="space-y-4">
                                    {[
                                        "Dostosowany do Twojej platofmy",
                                        "4 tryby działania (w tym dyskretny)",
                                        "Do 1000 odpowiedzi miesięcznie",
                                        "Oparty na najwowszym płatnym chatGPT",

                                    ].map((feature, index) => (
                                        <div key={index} className="flex items-center gap-3">
                                            <CheckCircle className="w-5 h-5 text-[#00D1A7] flex-shrink-0" />
                                            <span className="text-gray-700 text-sm sm:text-lg">{feature}</span>
                                        </div>
                                    ))}
                                </div>

                                {/* Disclaimer for Promotion */}
                                <div className="text-xs space-y-2 sm:text-sm text-gray-500 text-center">
                                    <p>
                                        * W ramach promocji dostęp do aplikacji obowiązuje wyłącznie do 1 stycznia 2025 roku.
                                    </p>
                                    <p>
                                        Po zakończeniu promocji możesz skorzystać z płatnego planu semestralnego na okres 5 miesięcy.
                                    </p>
                                </div>
                            </CardContent>

                            <CardFooter className="flex flex-col gap-4 pb-8">
                                <Link to="/register">
                                    <Button
                                        size="lg"
                                        className="w-full bg-gradient-to-r from-[#00D1A7] to-[#00B594] text-white font-semibold py-3 rounded-lg shadow hover:shadow-lg hover:from-[#00B594] hover:to-[#008F78] transition-all"
                                        onClick={() => {
                                            logEvent('Button', 'Click', 'Skorzystaj z promocji');
                                        }}
                                    >
                                        Skorzystaj z promocji
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>

                    </div>

                    {/* Trust Indicators */}
                    {/* <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto text-center">
                    {[
                        { icon: <CheckCircle className="w-6 h-6 text-[#00D1A7]" />, title: "Bez zobowiązań", description: "Możesz zrezygnować w dowolnym momencie" },
                        { icon: <Sparkles className="w-6 h-6 text-[#00D1A7]" />, title: "Pełna funkcjonalność", description: "Dostęp do wszystkich funkcji bez ograniczeń" },
                        { icon: <Timer className="w-6 h-6 text-[#00D1A7]" />, title: "Limitowana oferta", description: "Nie przegap okazji na darmowy dostęp" },
                    ].map((item, index) => (
                        <div key={index} className="space-y-2">
                            <div className="w-12 h-12 bg-[#00D1A7]/10 rounded-full flex items-center justify-center mx-auto">
                                {item.icon}
                            </div>
                            <h3 className="font-semibold text-[#1B4965]">{item.title}</h3>
                            <p className="text-sm text-gray-600">{item.description}</p>
                        </div>
                    ))}
                </div> */}

                    {/* FAQ Preview */}
                    <div className="mt-20 text-center">
                        <h2 className="text-2xl font-bold text-[#1B4965] mb-4">Masz pytania?</h2>
                        <p className="text-gray-600 mb-6">
                            Sprawdź naszą sekcję FAQ lub skontaktuj się z nami bezpośrednio
                        </p>
                        <Link to="/faq">
                            <Button
                                variant="outline"
                                className="text-[#1B4965] border-[#1B4965] hover:bg-[#1B4965] hover:text-white transition-all"
                            >
                                Zobacz FAQ
                            </Button>
                        </Link>
                    </div>
                </main>
            </div>
        </>
    );
}
