import {
    Accordion,
    AccordionItem,
    AccordionContent,
} from "../components/ui/accordion";
import { Helmet } from 'react-helmet';

export default function FAQ() {
    return (
        <>
            <Helmet>
                <title>FAQ - Najczęściej zadawane pytania | Quizonator</title>
                <meta
                    name="description"
                    content="Znajdź odpowiedzi na najczęściej zadawane pytania dotyczące korzystania z Quizonatora."
                />
            </Helmet>

            <section className="py-24 md:py-40 bg-gray-50 min-h-screen">
                <div className="container mx-auto px-8 md:px-12 max-w-5xl">
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold text-center mb-16 text-[#1B4965]">
                        Najczęściej Zadawane Pytania
                    </h1>

                    <Accordion type="single" collapsible className="space-y-6">
                        {[
                            {
                                question: "Czy korzystanie z Quizonatora jest uważane za oszustwo?",
                                answer: "Quizonator jest narzędziem edukacyjnym zaprojektowanym, aby pomóc w nauce i zrozumieniu materiału. Zachęcamy do odpowiedzialnego korzystania z naszego narzędzia zgodnie z polityką uczciwości akademickiej Twojej instytucji edukacyjnej. Quizonator najlepiej sprawdza się jako pomoc w nauce i przygotowaniu do testów, a nie jako sposób na ich obejście."
                            },
                            {
                                question: "Jak dokładne są odpowiedzi dostarczane przez Quizonator?",
                                answer: "Nasz system oparty na sztucznej inteligencji został przeszkolony na ogromnej bazie danych i stale się uczy. Dążymy do zapewnienia jak najwyższej dokładności, ale zawsze zalecamy weryfikację ważnych informacji. Quizonator jest narzędziem wspomagającym naukę, a nie zastępującym krytyczne myślenie i własną wiedzę."
                            },
                            {
                                question: "Czy mogę korzystać z Quizonatora na moim urządzeniu mobilnym?",
                                answer: "Obecnie Quizonator jest dostępny jako rozszerzenie dla przeglądarki Chrome na komputerach stacjonarnych. Pracujemy nad rozwiązaniami mobilnymi, które planujemy wprowadzić w przyszłości. Śledź nasze aktualizacje, aby być na bieżąco z nowymi funkcjami i platformami."
                            },
                            {
                                question: "Czy moje dane są bezpieczne i prywatne?",
                                answer: "Bezpieczeństwo i prywatność danych naszych użytkowników są dla nas priorytetem. Wszystkie dane użytkowników są szyfrowane i nigdy nie są udostępniane stronom trzecim. Stosujemy najnowsze protokoły bezpieczeństwa, aby chronić Twoje informacje. Szczegółowe informacje można znaleźć w naszej polityce prywatności."
                            },
                            {
                                question: "Czy Quizonator obsługuje wszystkie przedmioty i poziomy edukacji?",
                                answer: "Quizonator został zaprojektowany, aby obsługiwać szeroki zakres przedmiotów i poziomów edukacji, od szkoły średniej po studia wyższe. Nasza baza danych obejmuje wiele dziedzin, w tym nauki ścisłe, humanistyczne, społeczne i wiele innych. Stale rozszerzamy naszą bazę wiedzy, aby zapewnić jak najlepsze wsparcie dla różnorodnych potrzeb edukacyjnych."
                            },
                        ].map((item, index) => (
                            <AccordionItem key={index} title={item.question} className="text-lg md:text-xl">
                                <AccordionContent className="text-lg md:text-xl">{item.answer}</AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>
        </>
    );
}
