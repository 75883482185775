import React from "react";

// Główny komponent Table
export const Table = ({ children, className }) => {
    return (
        <table className={`w-full border-collapse ${className || ""}`}>
            {children}
        </table>
    );
};

// Nagłówek tabeli
export const TableHeader = ({ children, className }) => {
    return (
        <thead className={className}>
            {children}
        </thead>
    );
};

// Wiersz nagłówka tabeli
export const TableRow = ({ children, className }) => {
    return (
        <tr className={className}>
            {children}
        </tr>
    );
};

// Komórka nagłówka tabeli
export const TableHead = ({ children, className }) => {
    return (
        <th className={`border-b px-4 py-2 text-left text-sm font-medium text-gray-700 ${className || ""}`}>
            {children}
        </th>
    );
};

// Ciało tabeli
export const TableBody = ({ children, className }) => {
    return (
        <tbody className={className}>
            {children}
        </tbody>
    );
};

// Komórka tabeli
export const TableCell = ({ children, className }) => {
    return (
        <td className={`border-b px-4 py-2 text-sm text-gray-800 ${className || ""}`}>
            {children}
        </td>
    );
};
