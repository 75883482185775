import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';

const ChangePasswordModal = ({ isOpen, onClose, onChangePassword }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState(false); // Zmienione na `boolean`

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');
        setPasswordSuccess(false);

        if (!currentPassword) {
            setPasswordError('Wprowadź aktualne hasło.');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setPasswordError('Hasła się nie zgadzają.');
            return;
        }

        if (newPassword.length < 6) {
            setPasswordError('Hasło musi mieć co najmniej 6 znaków.');
            return;
        }

        try {
            await onChangePassword(currentPassword, newPassword);
            setPasswordSuccess(true); // Ustaw sukces
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            setPasswordError(error.message || 'Nie udało się zmienić hasła.');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-gray-900">Zmień hasło</h2>
            </ModalHeader>
            <ModalBody>
                {passwordSuccess ? (
                    <div className="text-center space-y-4">
                        <h3 className="text-xl font-bold text-green-600">Hasło zostało zmienione!</h3>
                        <p className="text-gray-600">Na Twój adres e-mail wysłaliśmy potwierdzenie zmiany hasła.</p>
                        <Button onClick={onClose} variant="primary">
                            Zamknij
                        </Button>
                    </div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <Label htmlFor="currentPassword">Aktualne hasło</Label>
                            <Input
                                id="currentPassword"
                                type="password"
                                placeholder="Wprowadź aktualne hasło"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="newPassword">Nowe hasło</Label>
                            <Input
                                id="newPassword"
                                type="password"
                                placeholder="Wprowadź nowe hasło"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <Label htmlFor="confirmNewPassword">Potwierdź nowe hasło</Label>
                            <Input
                                id="confirmNewPassword"
                                type="password"
                                placeholder="Powtórz nowe hasło"
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                required
                            />
                        </div>
                        {passwordError && <div className="text-red-500 text-sm">{passwordError}</div>}
                        <div className="flex justify-end space-x-2">
                            <Button variant="outline" onClick={onClose}>
                                Anuluj
                            </Button>
                            <Button type="submit" variant="primary">
                                Zmień hasło
                            </Button>
                        </div>
                    </form>
                )}
            </ModalBody>
        </Modal>
    );
};

export default ChangePasswordModal;
