import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardDescription, CardTitle } from '../../ui/card';
import { Button } from '../../ui/button';
import BackButton from '../BackButton';
import ManageSubscription from './ManageSubscription'; // Import komponentu do zarządzania subskrypcją
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../ui/table"
import { ArrowLeft, HelpCircle } from 'lucide-react'
import { Badge } from "../../ui/badge"


const Payments = ({ userData, transactions, onBack }) => {
    const [isManagingSubscription, setIsManagingSubscription] = useState(false); // Dodaj stan

    const handleManageSubscription = () => {
        setIsManagingSubscription(true); // Przełącz na widok zarządzania subskrypcją
    };

    const handleBackToPayments = () => {
        setIsManagingSubscription(false); // Powrót do widoku płatności
    };


    // if (isManagingSubscription) {
    //     return (
    //         <ManageSubscription
    //             subscription={{
    //                 name: userData.subscriptionStatus,
    //                 originalPrice: "120 zł",
    //                 discountedPrice: "0 zł",
    //                 endDate: userData.subscriptionEndDate,
    //                 history: transactions.map((transaction) => ({
    //                     date: transaction.date,
    //                     name: "Plan semestralny", // Możesz dodać logikę do dynamicznej nazwy
    //                     price: transaction.amount,
    //                     status: transaction.status === "Sukces" ? "Aktywny" : "Nieudany",
    //                 })),
    //             }}
    //             onBack={handleBackToPayments} // Powrót do widoku płatności
    //         />
    //     );
    // }

    return (
        // <Card className="max-w-4xl mx-auto p-6 shadow-lg rounded-lg bg-white">
        //     <BackButton onClick={onBack} />

        //     <CardHeader className="flex items-center justify-between border-b pb-4 mb-4">
        //         <div className="flex items-center space-x-4">
        //             <div>
        //                 <CardTitle className="text-2xl font-semibold text-gray-800">Płatności</CardTitle>
        //                 <CardDescription className="text-sm text-gray-600">
        //                     Zarządzaj swoją subskrypcją i historią transakcji
        //                 </CardDescription>
        //             </div>
        //         </div>
        //         <Link to="/kontakt">
        //             <Button className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors">
        //                 Pomoc
        //             </Button>
        //         </Link>
        //     </CardHeader>

        //     <CardContent className="space-y-8">
        //         {/* Sekcja subskrypcji */}
        //         <section>
        //             <h3 className="text-lg font-semibold text-gray-800">Status subskrypcji</h3>
        //             <div className="flex items-center justify-between border rounded-lg p-4 bg-gray-50">
        //                 <div>
        //                     <p className="text-sm text-gray-600">Obecny plan:</p>
        //                     <h4 className="text-lg font-medium text-gray-800">
        //                         {userData?.subscriptionStatus || 'Brak subskrypcji'}
        //                     </h4>
        //                     <p className="text-sm text-gray-500">
        //                         {userData?.subscriptionEndDate
        //                             ? `Ważna do: ${new Date(userData.subscriptionEndDate).toLocaleDateString()}`
        //                             : 'Data wygaśnięcia nieznana'}
        //                     </p>
        //                 </div>
        //                 {/* <Button
        //                     variant="default"
        //                     onClick={handleManageSubscription} // Przejdź do zarządzania subskrypcją
        //                 >
        //                     Zarządzaj subskrypcją
        //                 </Button> */}
        //             </div>
        //         </section>

        //         {/* Historia transakcji */}
        //         <section>
        //             <h3 className="text-lg font-semibold text-gray-800">Historia transakcji</h3>
        //             <div className="border rounded-lg p-4 bg-gray-50">
        //                 {transactions.length > 0 ? (
        //                     <table className="w-full table-auto">
        //                         <thead>
        //                             <tr className="text-left text-sm text-gray-600 border-b">
        //                                 <th className="py-2">Data</th>
        //                                 <th className="py-2">Opis</th>
        //                                 <th className="py-2">Kwota</th>
        //                                 <th className="py-2">Status</th>
        //                             </tr>
        //                         </thead>
        //                         <tbody className="divide-y divide-gray-200">
        //                             {transactions.map((transaction) => (
        //                                 <tr key={transaction.id} className="text-sm text-gray-800">
        //                                     <td className="py-2">{transaction.date}</td>
        //                                     <td className="py-2">{transaction.description}</td>
        //                                     <td className="py-2">{transaction.amount}</td>
        //                                     <td
        //                                         className={`py-2 font-medium ${transaction.status === "Sukces" ? "text-green-600" : "text-red-600"
        //                                             }`}
        //                                     >
        //                                         {transaction.status}
        //                                     </td>
        //                                 </tr>
        //                             ))}
        //                         </tbody>
        //                     </table>
        //                 ) : (
        //                     <p className="text-sm text-gray-600 text-center py-4">Brak transakcji</p>
        //                 )}
        //             </div>
        //         </section>
        //     </CardContent>
        // </Card>
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <Button variant="ghost" onClick={onBack} className="flex items-center gap-2">
                    <ArrowLeft className="h-4 w-4" />
                    Powrót
                </Button>
                <Link to="/kontakt">
                    <Button variant="outline" className="flex items-center gap-2">
                        <HelpCircle className="h-4 w-4" />
                        Pomoc
                    </Button>
                </Link>
            </div>

            <div>
                <h2 className="text-3xl font-bold tracking-tight">Płatności</h2>
                <p className="text-muted-foreground">
                    Zarządzaj swoją subskrypcją i historią transakcji
                </p>
            </div>

            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle className='text-2xl font-semibold'>Status subskrypcji</CardTitle>
                    <CardDescription>Informacje o Twoim obecnym planie</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                    <div>
                        {new Date() < new Date(userData?.subscriptionEndDate._seconds * 1000 + userData?.subscriptionEndDate._nanoseconds / 1000000) ? "Aktywna" : "Nieaktywna"}
                        <p className="text-xs sm:text-sm text-muted-foreground">
                            {userData?.subscriptionEndDate
                                ? `Ważna do: ${new Date(userData.subscriptionEndDate._seconds * 1000 + userData.subscriptionEndDate._nanoseconds / 1000000).toLocaleDateString()}`
                                : 'Data wygaśnięcia nieznana'}
                        </p>
                    </div>
                    {/* <Button className="w-full sm:w-auto" onClick={handleManageSubscription}>
                        Zarządzaj subskrypcją
                    </Button> */}
                </CardContent>

            </Card>

            <Card className='border border-gray-300'>
                <CardHeader>
                    <CardTitle>Historia transakcji</CardTitle>
                    <CardDescription>Przegląd Twoich ostatnich płatności</CardDescription>
                </CardHeader>
                <CardContent>
                    {transactions.length > 0 ? (
                        <>
                            {/* Widok tabeli dla większych ekranów */}
                            <div className="hidden sm:block overflow-x-auto">
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead>Data</TableHead>
                                            <TableHead>Opis</TableHead>
                                            <TableHead>Kwota</TableHead>
                                            <TableHead>Status</TableHead>
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {transactions.map((transaction) => (
                                            <TableRow key={transaction.id}>
                                                <TableCell>{transaction.date}</TableCell>
                                                <TableCell>{transaction.description}</TableCell>
                                                <TableCell>{transaction.amount}</TableCell>
                                                <TableCell>
                                                    <Badge color={transaction.status === "Sukces" ? "green" : "red"}>
                                                        {transaction.status}
                                                    </Badge>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            {/* Widok listy dla małych ekranów */}
                            <div className="block sm:hidden space-y-4">
                                {transactions.map((transaction) => (
                                    <div
                                        key={transaction.id}
                                        className="p-4 border rounded-lg bg-gray-50 shadow-sm flex flex-col space-y-2"
                                    >
                                        <div className="flex justify-between items-center">
                                            <span className="font-medium text-sm">{transaction.date}</span>
                                            <Badge color={transaction.status === "Sukces" ? "green" : "red"}>
                                                {transaction.status}
                                            </Badge>
                                        </div>
                                        <p className="text-xs text-gray-600">{transaction.description}</p>
                                        <p className="text-sm font-bold">{transaction.amount}</p>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <p className="text-center py-4 text-muted-foreground">Brak transakcji</p>
                    )}

                </CardContent>
            </Card>
        </div>
    );
};

export default Payments;