import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { BrainCircuit, FileQuestion, EyeOff } from 'lucide-react';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
// import DefaultModeGif from '../assets/tutorial/defaultmode.webp';
import DefaultModeGif from '../assets/defmode.gif';
import { Star } from 'lucide-react';
import { logEvent } from '../analytics';
import { Helmet } from 'react-helmet';

export default function Home() {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const targetDate = new Date("2025-01-01T00:00:00");

        const updateCountdown = () => {
            const now = new Date();
            const difference = targetDate.getTime() - now.getTime();

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / 1000 / 60) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({ days, hours, minutes, seconds });
        };

        updateCountdown();
        const timer = setInterval(updateCountdown, 1000);

        return () => clearInterval(timer);
    }, []);


    useEffect(() => {
        logEvent('PageView', 'Home', 'Odwiedzono stronę główną');
    }, []);


    return (
        <>
            <Helmet>
                <title>Quizonator - Zdobądź świetne oceny dzięki Quizonatorowi!</title>
                <meta
                    name="description"
                    content="Quizonator to inteligentne rozszerzenie Chrome, które pomaga studentom rozwiązywać quizy i testy online."
                />
                <meta name="keywords" content="Quizonator, quiz, testy online, nauka, studenci, AI" />
                {/* Open Graph tags */}
                <meta property="og:title" content="Quizonator - Zdobądź świetne oceny dzięki Quizonatorowi!" />
                <meta
                    property="og:description"
                    content="Quizonator to inteligentne rozszerzenie Chrome, które pomaga studentom rozwiązywać quizy i testy online."
                />
                <meta property="og:image" content="https://quizonator.com/og-image.png" />
                <meta property="og:url" content="https://quizonator.com/" />
                <meta property="og:type" content="website" />
                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Quizonator - Zdobądź świetne oceny dzięki Quizonatorowi!" />
                <meta
                    name="twitter:description"
                    content="Quizonator to inteligentne rozszerzenie Chrome, które pomaga studentom rozwiązywać quizy i testy online."
                />
                <meta name="twitter:image" content="https://quizonator.com/twitter-image.png" />
            </Helmet>

            {/* Hero Section */}
            <section className="py-20 md:py-32">
                <div className="container mx-auto px-4 md:px-6">
                    <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
                        {/* Tekst hero */}
                        <div className="lg:w-1/2 space-y-8">
                            <h1 className="text-5xl md:text-6xl font-bold tracking-tighter text-[#1B4965]">
                                Zdobądź świetne oceny dzięki Quizonatorowi!
                            </h1>
                            <p className="text-xl text-gray-600 max-w-lg">
                                Quizonator to inteligentne rozszerzenie Chrome, które pomaga studentom rozwiązywać quizy i testy online.
                            </p>

                            <div className="flex flex-col sm:flex-row gap-4">
                                <Link to="/cennik">
                                    <Button
                                        size="lg"
                                        className="text-lg bg-[#00D1A7] hover:bg-[#00B594] text-white"
                                        onClick={() => {
                                            logEvent('Home', 'Click', 'Rozpocznij darmowy okres próbny');
                                        }}
                                    >

                                        Rozpocznij darmowy okres próbny
                                    </Button>
                                </Link>
                                <Link to="/jak-to-dziala">
                                    <Button
                                        size="lg"
                                        variant="outline"
                                        className="text-lg border-[#1B4965] text-[#1B4965] hover:bg-[#1B4965] hover:text-white"
                                        onClick={() => {
                                            logEvent('Home', 'Click', 'Dowiedz się więcej');
                                        }}
                                    >
                                        Dowiedz się więcej
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        {/* Obrazek hero */}
                        <div className="lg:w-1/2">
                            <img
                                src={DefaultModeGif}
                                alt="Quizonator Preview"
                                width={600}
                                height={400}
                                className="rounded-lg shadow-2xl"
                            />
                        </div>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-20 bg-[#F8FAFC]">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        Główne Funkcje Quizonatora
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {[
                            {
                                icon: <BrainCircuit className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Automatyczne rozwiązywanie quizów",
                                description: "Sztuczna inteligencja analizuje pytania i natychmiast zaznacza poprawne odpowiedzi za Ciebie."
                            },
                            {
                                icon: <FileQuestion className="h-12 w-12 mb-4 text-[#2196F3]" />,
                                title: "Analiza zrzutów ekranu",
                                description: "Zrób zrzut ekranu quizu, a Quizonator przeanalizuje pytania i dostarczy precyzyjne odpowiedzi."
                            },
                            {
                                icon: <EyeOff className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Tryb dyskretny",
                                description: "Delikatne oznaczenie poprawnej odpowiedzi widoczne tylko dla Ciebie, niewidzialne dla innych."
                            },

                        ].map((feature, index) => (
                            <Card key={index} className="text-center bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
                                <CardHeader>
                                    <div className="flex justify-center">{feature.icon}</div>
                                    <CardTitle className="text-xl text-[#1B4965] font-semibold">{feature.title}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600">{feature.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>


            {/* Promo Section */}
            <section className="py-20 bg-gradient-to-r from-[#00D1A7] to-[#2196F3]">
                <div className="container mx-auto px-4 md:px-6 text-center text-white">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">🎉 Specjalna Promocja!</h2>
                    <p className="text-xl mb-8">Pełen dostęp za 0 zł do 1 stycznia 2025 roku!</p>
                    <div className="flex justify-center space-x-4 mb-8">
                        {Object.entries(timeLeft).map(([unit, value]) => (
                            <div
                                key={unit}
                                className="flex flex-col items-center p-4 bg-white bg-opacity-20 rounded-lg"
                            >
                                <span className="text-3xl font-bold">
                                    {value.toString().padStart(2, "0")}
                                </span>
                                <span className="text-sm uppercase">{unit}</span>
                            </div>
                        ))}
                    </div>
                    <Link to="/cennik">
                        <Button size="lg" className="text-lg bg-white text-[#1B4965] hover:bg-gray-200"
                            onClick={() => {
                                logEvent('Home', 'Click', 'Skorzystaj z promocji teraz');
                            }}
                        >
                            Skorzystaj z promocji teraz!
                        </Button>
                    </Link>
                </div>
            </section>

            {/* Testimonials Section */}
            <section className="py-20">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        Co mówią nasi użytkownicy
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {[
                            { name: "Bartek", school: "Akademia Humanitas", quote: "Dzięki programowi tak naprawdę zdałem 1 rok studiów, działa giga dobrze, bardzo ciężko go wykryć.", rating: 5 },
                            { name: "Dominika", school: "Uniwersytet WSB Merito", quote: "Genialna wtyczka, która ułatwiła mi jeszcze bardziej rozwiązywanie testów/quizów dając tym samym gwarancje zaliczenia.", rating: 5 },
                            { name: "Wiktor", school: "Uniwersytet WSB Merito", quote: "Zajebista opcja safe mod, pomimo że wykładowca stał nade mną dobrze wiedziałem jakie odpowiedzi zaznaczyc.", rating: 5 },
                        ].map((testimonial, index) => (
                            <Card key={index} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 rounded-xl p-6">
                                <CardHeader>
                                    <CardTitle className="text-lg md:text-xl font-bold text-[#1B4965]">{testimonial.name}</CardTitle>
                                    <CardDescription className="text-sm md:text-base text-gray-600">{testimonial.school}</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600 mb-4 italic">"{testimonial.quote}"</p>
                                    <div className="flex justify-center gap-1">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-5 w-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                fill={i < testimonial.rating ? 'currentColor' : 'none'}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>


            {/* CTA Section */}
            <section className="py-20 bg-[#1B4965]">
                <div className="container mx-auto px-4 md:px-6 text-center text-white">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">
                        Gotowy, by poprawić swoje oceny?
                    </h2>
                    <Link to="/cennik">
                        <Button
                            size="lg"
                            className="text-lg bg-[#00D1A7] hover:bg-[#00B594] text-white mt-12"
                        >
                            Rozpocznij darmowy okres próbny
                        </Button>
                    </Link>
                </div>
            </section>

            {/* Short Faq */}
            <section className="py-20 bg-[#F8FAFC]">
                <div className="container mx-auto px-4 md:px-6">
                    <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                        Najczęściej Zadawane Pytania
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {[
                            { question: "Jak działa Quizonator?", answer: "Quizonator działa poprzez analizowanie pytania i generowanie odpowiedzi przy pomocy sztucznej inteligencji." },
                            { question: "Czy Quizonator jest legalny?", answer: "Tak, Quizonator jest w pełni legalnym narzędziem." },
                            { question: "Jakie są korzyści z używania Quizonatora?", answer: "Quizonator to narzędzie, które pomaga zaoszczędzić czas i skutecznie poprawić wyniki w nauce." },
                        ].map((faq, index) => (
                            <Card key={index} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300 ">
                                <CardHeader>
                                    <CardTitle className="text-xl text-[#1B4965] font-semibold">{faq.question}</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600">{faq.answer}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
