import React from 'react';

const PrivacyPolicy = () => {
    const sections = [
        {
            title: "1. Informacje ogólne",
            description: "Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych użytkowników korzystających z rozszerzenia \"Quizonator\", zgodnie z obowiązującymi przepisami prawa polskiego, w tym z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO).",
        },
        {
            title: "2. Dane, które zbieramy",
            subsections: [
                {
                    title: "2.1. Dane uwierzytelniające",
                    description: "- Adres e-mail, nazwa użytkownika, hasło – zbierane w celu rejestracji i autoryzacji użytkownika w Rozszerzeniu.",
                },
                {
                    title: "2.2. Dane dotyczące urządzenia i logowania",
                    description: "- Informacje o urządzeniu oraz adres IP – zbierane przy logowaniu w celu zminimalizowania nadużyć i zapewnienia bezpieczeństwa.",
                },
                {
                    title: "2.3. Dane dotyczące użytkowania",
                    description: "- Treść pytań i odpowiedzi z quizów – przesyłane do ChatGPT w celu generowania sugestii odpowiedzi. Nie ponosimy odpowiedzialności za treści przesyłane przez użytkownika.\n- Podsumowania quizów – zapisywane w bazie danych w celach statystycznych. Użytkownik wyraża na to zgodę, korzystając z Rozszerzenia.",
                },
            ],
        },
        {
            title: "3. Cel przetwarzania danych",
            description: "- Zapewnienie funkcjonalności Rozszerzenia – przetwarzanie danych jest niezbędne do świadczenia usług oferowanych przez Rozszerzenie.\n- Autoryzacja i rejestracja użytkowników – w celu umożliwienia dostępu do konta i jego zabezpieczenia.\n- Zapobieganie nadużyciom – monitorowanie logowań w celu wykrycia i zapobiegania nieautoryzowanym działaniom.\n- Analiza statystyczna – w celu poprawy jakości usług i dostosowania ich do potrzeb użytkowników.",
        },
        {
            title: "4. Podstawa prawna przetwarzania danych",
            description: "Przetwarzanie danych osobowych odbywa się na podstawie:\n\n- Art. 6 ust. 1 lit. a RODO – zgoda użytkownika wyrażona poprzez akceptację niniejszej Polityki Prywatności i korzystanie z Rozszerzenia.\n- Art. 6 ust. 1 lit. b RODO – niezbędność przetwarzania do wykonania umowy, której stroną jest użytkownik.",
        },
        {
            title: "5. Udostępnianie danych",
            description: "- Dane osobowe użytkowników nie są udostępniane podmiotom trzecim, chyba że jest to wymagane przez obowiązujące przepisy prawa.\n- Treść pytań i odpowiedzi przesyłana jest do ChatGPT w celu świadczenia funkcjonalności Rozszerzenia.",
        },
        {
            title: "6. Bezpieczeństwo danych",
            description: "- Stosujemy odpowiednie środki techniczne i organizacyjne w celu ochrony danych osobowych przed nieuprawnionym dostępem, utratą czy modyfikacją.\n- Dostęp do danych mają wyłącznie upoważnione osoby, zobowiązane do zachowania ich poufności.",
        },
        {
            title: "7. Prawa użytkownika",
            description: "Użytkownik ma prawo do:",
            rights: [
                "- Dostępu do swoich danych – uzyskania informacji o przetwarzanych danych osobowych.",
                "- Sprostowania danych – poprawienia niekompletnych lub nieprawidłowych danych.",
                "- Usunięcia danych – żądania usunięcia danych w przypadkach przewidzianych prawem.",
                "- Ograniczenia przetwarzania – żądania ograniczenia przetwarzania danych w określonych sytuacjach.",
                "- Przenoszenia danych – otrzymania swoich danych w ustrukturyzowanym formacie.",
                "- Wycofania zgody – w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania przed jej wycofaniem.",
            ],
            contact: "W celu skorzystania z powyższych praw należy skontaktować się z nami pod adresem: support@quizonator.com",
        },
        {
            title: "8. Okres przechowywania danych",
            description: "Dane osobowe przechowywane są przez okres niezbędny do realizacji celów, dla których zostały zebrane, lub do momentu wycofania zgody przez użytkownika.",
        },
        {
            title: "9. Zmiany w Polityce Prywatności",
            description: "- Zastrzegamy sobie prawo do wprowadzania zmian w Polityce Prywatności.\n- Aktualizacje będą publikowane na stronie Rozszerzenia z odpowiednim wyprzedzeniem.",
        },
        {
            title: "10. Kontakt",
            description: "W przypadku pytań lub wątpliwości dotyczących niniejszej Polityki Prywatności prosimy o kontakt:\n\n- E-mail: support@quizonator.com",
        },
    ];

    return (
        <div className="min-h-screen p-8 bg-white text-gray-800">
            <div className="container mx-auto text-left rounded-lg p-6">
                <h1 className="text-4xl font-bold mb-6">Polityka Prywatności rozszerzenia "Quizonator"</h1>
                <p className="mb-6 text-lg">Data ostatniej aktualizacji: 2.12.2024</p>

                {sections.map((section, index) => (
                    <section key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold mb-4">{section.title}</h2>
                        {section.description && (
                            <p className="mb-4 whitespace-pre-line">{section.description}</p>
                        )}
                        {section.subsections &&
                            section.subsections.map((subsection, subIndex) => (
                                <div key={subIndex}>
                                    <h3 className="text-xl font-semibold mb-2">{subsection.title}</h3>
                                    <p className="mb-4 whitespace-pre-line">{subsection.description}</p>
                                </div>
                            ))}
                        {section.rights && (
                            <ul className="list-disc ml-8">
                                {section.rights.map((right, rightIndex) => (
                                    <li key={rightIndex} className="mb-2">
                                        {right}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {section.contact && <p className="mb-4">{section.contact}</p>}
                    </section>
                ))}
            </div>
        </div>
    );
};

export default PrivacyPolicy;
