import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { Mail } from 'lucide-react';
import emailjs from "@emailjs/browser";
import { Helmet } from 'react-helmet';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState({ success: null, error: null }); // Obsługa sukcesu/błędu
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ success: null, error: null });
    setIsLoading(true);

    try {
      const response = await emailjs.send(
        "service_jilb45r",
        "template_dlnitpa",
        {
          from_name: formData.name,
          reply_to: formData.email,
          subject: formData.subject,
          message: formData.message,
        },
        "fET1LcHI6nMtO6HLS"
      );

      if (response.status === 200) {
        setStatus({ success: "Wiadomość została wysłana pomyślnie!", error: null });
        setFormData({ name: "", email: "", subject: "", message: "" }); // Wyczyść formularz
      }
    } catch (error) {
      setStatus({ success: null, error: "Wystąpił błąd podczas wysyłania wiadomości." });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <Helmet>
        <title>Kontakt - Skontaktuj się z nami | Quizonator</title>
        <meta
          name="description"
          content="Masz pytania lub potrzebujesz pomocy? Skontaktuj się z nami poprzez formularz kontaktowy lub e-mail."
        />
      </Helmet>

      <section className="py-12 md:py-20 lg:py-32 bg-gray-50 min-h-screen">
        <div className="container max-w-6xl px-4 md:px-6 lg:px-8 mx-auto">
          <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-center mb-12 text-[#1B4965]">
            Skontaktuj się z nami
          </h1>

          <div className="max-w-2xl mx-auto">
            <Card className="shadow-lg p-6 rounded-lg bg-white">
              <CardHeader>
                <CardTitle className="text-xl sm:text-2xl font-semibold text-[#1B4965]">
                  Masz pytanie? Napisz do nas!
                </CardTitle>
                <CardDescription className="text-sm sm:text-base text-gray-600 mt-4">
                  Skorzystaj z formularza lub napisz bezpośrednio na nasz adres e-mail.
                </CardDescription>
              </CardHeader>
              <div className="flex items-center space-x-4 p-3 border border-gray-300 rounded-lg my-6">
                <Mail className="h-8 w-8 text-[#00D1A7]" />
                <div>
                  <p className="text-gray-700 font-semibold">support@quizonator.com</p>
                </div>
              </div>
              <CardContent>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <Input
                    name="name"
                    placeholder="Imię"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <Input
                    name="subject"
                    placeholder="Temat"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                  <Textarea
                    name="message"
                    placeholder="Twoja wiadomość"
                    rows={5}
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <Button
                    type="submit"
                    className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                    disabled={isLoading} // Wyłącz przycisk podczas ładowania
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-5 w-5 mr-2 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 2.042.68 3.918 1.816 5.434l2.184-2.143z"
                          ></path>
                        </svg>
                        Ładowanie...
                      </span>
                    ) : (
                      "Wyślij wiadomość"
                    )}
                  </Button>

                </form>
              </CardContent>
              <CardFooter>
                {status.success && (
                  <p className="text-green-600 text-center font-medium">{status.success}</p>
                )}
                {status.error && (
                  <p className="text-red-600 text-center font-medium">{status.error}</p>
                )}
              </CardFooter>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
