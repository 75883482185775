import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BrainCircuit, FileQuestion, Zap, EyeOff, Clock, ShieldCheck } from 'lucide-react';
import { Helmet } from 'react-helmet';

export default function Features() {
    return (
        <>
            <Helmet>
                <title>Zalety - Poznaj korzyści z Quizonatora</title>
                <meta
                    name="description"
                    content="Dowiedz się, jakie zalety oferuje Quizonator i jak może pomóc Ci w nauce i rozwiązywaniu quizów."
                />
            </Helmet>

            <section className="py-16 md:py-24 lg:py-32 bg-gray-50 min-h-screen">
                <div className="container max-w-7xl px-6 md:px-8 lg:px-10 mx-auto">
                    {/* Nagłówek sekcji */}
                    <h1 className="text-4xl md:text-5xl lg:text-6xl  font-extrabold text-center text-[#1B4965] mb-6 md:mb-8">
                        Kluczowe zalety  Quizonatora
                    </h1>
                    <p className="text-lg md:text-xl text-center text-gray-700 mb-12 md:mb-16 max-w-3xl mx-auto">
                        Quizonator to narzędzie, które automatyzuje rozwiązywanie quizów, łącząc szybkość, dyskrecję i wygodę użytkowania.
                    </p>

                    {/* Siatka funkcji */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {[
                            {
                                icon: <BrainCircuit className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Automatyczne działanie",
                                description: "Quizonator automatycznie wykrywa pytania i zaznacza odpowiedzi."
                            },
                            {
                                icon: <FileQuestion className="h-12 w-12 mb-4 text-[#2196F3]" />,
                                title: "Najwyższa jakość",
                                description: "Wykorzystujemy najmowszy dostępny model chatuGPT."
                            },
                            {
                                icon: <Zap className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Szybkie generowanie odpowiedzi",
                                description: "Odpowiedzi są generowane w ciągu kilku sekund."
                            },
                            {
                                icon: <EyeOff className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Dyskretne działanie",
                                description: "Rozszerzenie działa niezauważalnie, dzięki czemu zachowuje pełną dyskrecję."
                            },
                            {
                                icon: <Clock className="h-12 w-12 mb-4 text-[#2196F3]" />,
                                title: "Podgląd zrealizowanych quizów",
                                description: "Uzyskaj łatwy dostęp do historii wykonanych przez Ciebie quizów."
                            },
                            {
                                icon: <ShieldCheck className="h-12 w-12 mb-4 text-[#00D1A7]" />,
                                title: "Bezpieczeństwo i prywatność",
                                description: "Pełne bezpieczeństwo i prywatność Twoich danych i wyników."
                            },
                        ].map((feature, index) => (
                            <Card
                                key={index}
                                className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 text-center p-6"
                            >
                                <CardHeader>
                                    <div className="flex justify-center">{feature.icon}</div>
                                    <CardTitle className="text-xl font-bold text-[#1B4965] mb-3">
                                        {feature.title}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-600 text-base">{feature.description}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
