// src/pages/VerifyEmail.jsx

import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { applyActionCode, getIdToken, reload, sendEmailVerification } from "firebase/auth";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import axios from 'axios';
import { Modal, ModalContent, ModalHeader, ModalFooter, ModalTitle, ModalBody } from "../components/ui/modal"; // Zakładając, że masz komponent Modal
import { checkActionCode } from "firebase/auth";

export default function VerifyEmailPage() {
    const [isVerifying, setIsVerifying] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isProcessed = useRef(false); // Ref to prevent multiple calls
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const verifyEmail = async () => {
            const params = new URLSearchParams(location.search);
            const oobCode = params.get('oobCode');

            if (!oobCode) {
                setError('Nieautoryzowany dostęp');
                setIsVerifying(false);
                return;
            }

            try {
                // Get user's email using the action code
                const info = await checkActionCode(auth, oobCode);
                const email = info.data.email;

                // Apply the action code to verify the email
                await applyActionCode(auth, oobCode);

                // Activate the user's account on the backend
                await axios.post('https://us-central1-quizonator-a4513.cloudfunctions.net/api/activate-account', { email });

                setSuccess(true);

                // Start countdown and navigate to login
                const timer = setInterval(() => {
                    setCountdown(prev => {
                        if (prev <= 1) {
                            clearInterval(timer);
                            navigate('/login');
                            return 0;
                        }
                        return prev - 1;
                    });
                }, 1000);
            } catch (err) {
                console.error('Błąd podczas weryfikacji emaila:', err);
                setError(err.message || 'Wystąpił błąd podczas weryfikacji emaila.');
            } finally {
                setIsVerifying(false);
            }
        };

        verifyEmail();
    }, [location.search, navigate]);



    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-gray-50 to-gray-100 px-4 sm:px-6 lg:px-8">
            <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                <CardHeader className="text-center space-y-1">
                    <CardTitle className="text-3xl font-semibold text-[#1B4965]">Potwierdź swój email</CardTitle>
                </CardHeader>
                <CardContent className="p-6 space-y-6">
                    {isVerifying ? (
                        <p>Weryfikowanie...</p>
                    ) : error ? (
                        <Alert variant="destructive" className="flex items-center space-x-2">
                            <div>
                                <AlertTitle>Błąd</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </div>
                        </Alert>
                    ) : success ? (
                        <Alert variant="success" className="flex items-center space-x-2">
                            <div>
                                <AlertTitle>Sukces</AlertTitle>
                                <AlertDescription>
                                    Email został pomyślnie zweryfikowany. Przekierowanie na login za {countdown} sekund...
                                </AlertDescription>
                            </div>
                        </Alert>
                    ) : null}
                </CardContent>
                <CardFooter className="text-center text-sm text-gray-600">
                    {success && (
                        <Button disabled className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800">
                            Zaloguj się
                        </Button>
                    )}
                    {error && (
                        <Button onClick={() => navigate('/login')} className="w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-800">
                            Wróć do logowania
                        </Button>
                    )}
                </CardFooter>
            </Card>
        </div>
    );
}
