export const ScrollArea = ({ children, className, style }) => {
    return (
        <div
            className={`custom-scroll overflow-auto h-[400px] sm:h-[600px] lg:h-[600px] ${className || ""}`}
            style={style} // Inline style nadal można przekazywać
        >
            {children}
        </div>
    );
};
