// src/analytics.js
import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

let isGAInitialized = false;

export const initGA = () => {
    const consent = getCookieConsentValue();
    if (consent === 'true' && !isGAInitialized) {
        ReactGA.initialize('G-3P7GK45YJJ'); // Wstaw swój ID pomiaru Google Analytics
        isGAInitialized = true;
    }
};

export const logPageView = (path) => {
    const consent = getCookieConsentValue();
    if (consent === 'true' && isGAInitialized) {
        ReactGA.send({ hitType: 'pageview', page: path });
    }
};

export const logEvent = (category, action, label) => {
    const consent = getCookieConsentValue();
    if (consent === 'true' && isGAInitialized) {
        ReactGA.event({ category, action, label });
    }
};
