import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../components/ui/card";
import { Star } from 'lucide-react';
import { Helmet } from 'react-helmet';

export default function Testimonials() {
    return (
        <>
            <Helmet>
                <title>Opinie - Co mówią nasi użytkownicy | Quizonator</title>
                <meta
                    name="description"
                    content="Przeczytaj opinie naszych zadowolonych użytkowników i dowiedz się, jak Quizonator pomógł im w nauce."
                />
            </Helmet>

            <section className="py-20 md:py-32 bg-gray-50  min-h-screen">
                <div className="container max-w-6xl px-6 md:px-10 mx-auto">
                    {/* Nagłówek sekcji */}
                    <h1 className="text-4xl md:text-5xl lg:text-6xl  font-extrabold text-center mb-8 md:mb-12 text-[#1B4965]">
                        Opinie naszych użytkowników
                    </h1>
                    <p className="text-lg md:text-xl text-center mb-12 text-gray-700 max-w-3xl mx-auto">
                        Zobacz, co mówią o Quizonatorze studenci z całej Polski.
                    </p>

                    {/* Siatka opinii */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {[
                            { name: "Bartek", school: "Akademia Humanitas", quote: "Dzięki temu programowi tak naprawdę zdałem 1 rok studiów, działa giga dobrze, bardzo ciężko go wykryć.", rating: 5 },
                            { name: "Dominika", school: "Uniwersytet WSB Merito", quote: "Genialna wtyczka, która ułatwiła mi jeszcze bardziej rozwiązywanie testów/quizów dając tym samym gwarancje zaliczenia.", rating: 5 },
                            { name: "Wiktor", school: "Uniwersytet WSB Merito", quote: "Zajebista opcja safe mod, pomimo że wykładowca stał nade mną dobrze wiedziałem jakie odpowiedzi zaznaczyc", rating: 5 },

                        ].map((testimonial, index) => (
                            <Card key={index} className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-xl p-6 text-center">
                                <CardHeader>
                                    <CardTitle className="text-xl font-bold text-[#1B4965]">{testimonial.name}</CardTitle>
                                    <CardDescription className="text-sm text-gray-600">{testimonial.school}</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <p className="text-gray-700 mb-4 italic">"{testimonial.quote}"</p>
                                    <div className="flex justify-center gap-1">
                                        {Array.from({ length: 5 }).map((_, i) => (
                                            <Star
                                                key={i}
                                                className={`h-5 w-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                                                fill={i < testimonial.rating ? 'currentColor' : 'none'}
                                            />
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}
