// src/components/dashboard/modals/EmailVerificationModal.jsx

import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../ui/modal';
import { Button } from '../../ui/button';
import { Alert, AlertDescription, AlertTitle } from '../../ui/alert';

const EmailVerificationModal = ({ isOpen, onClose, onResendVerification }) => {
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [canResend, setCanResend] = useState(true);
    const [resendTimer, setResendTimer] = useState(0);

    const handleResendClick = async () => {
        setIsLoading(true);
        setError('');
        setMessage('');

        try {
            await onResendVerification();
            setMessage('Email weryfikacyjny został ponownie wysłany.');
            setCanResend(false);
            setResendTimer(60); // Ustaw czas oczekiwania (np. 60 sekund)

            // Uruchom licznik czasu
            const timerInterval = setInterval(() => {
                setResendTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(timerInterval);
                        setCanResend(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (err) {
            setError('Nie udało się wysłać ponownie emaila weryfikacyjnego. Spróbuj ponownie później.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                <h2 className="text-lg font-medium text-[#1B4965]">Weryfikacja Emaila</h2>
            </ModalHeader>
            <ModalBody>
                <p className="text-gray-700">
                    Proszę zweryfikować swój adres email, aby uzyskać pełny dostęp do funkcji aplikacji.
                </p>
                <p className="text-gray-700">
                    Sprawdź swoją skrzynkę odbiorczą i kliknij w link weryfikacyjny.
                </p>

                {error && (
                    <Alert variant="destructive" className="mt-4">
                        <AlertTitle>Błąd</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                {message && (
                    <Alert variant="success" className="mt-4">
                        <AlertTitle>Sukces</AlertTitle>
                        <AlertDescription>{message}</AlertDescription>
                    </Alert>
                )}
            </ModalBody>
            <ModalFooter className="flex justify-between">
                <Button
                    onClick={handleResendClick}
                    disabled={!canResend || isLoading}
                    className="bg-[#00D1A7] text-white hover:bg-[#00B594]"
                >
                    {isLoading ? 'Wysyłanie...' : canResend ? 'Wyślij ponownie email weryfikacyjny' : `Spróbuj ponownie za ${resendTimer}s`}
                </Button>
                <Button onClick={onClose} className="bg-gray-500 text-white hover:bg-gray-600">
                    Zamknij
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default EmailVerificationModal;
