import { Button } from '../components/ui/button';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { Menu, X } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import { Home, Star, DollarSign, Info, HelpCircle, MessageSquare, BookOpen, Phone } from 'lucide-react';
import { logEvent } from '../analytics';
import { PromotionPopup } from "./PromoModal";

export default function Layout({ children }) {

    const [user, setUser] = useState(null);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
    // useEffect(() => {
    //     const popupShown = sessionStorage.getItem('promotionPopupShown');

    //     // Wyświetlaj tylko dla niezalogowanych użytkowników i jeśli popup nie był jeszcze pokazany w sesji
    //     // Dodano warunek, aby popup nie był wyświetlany na stronach resetowania hasła i weryfikacji email
    //     if (!user && !popupShown && location.pathname !== '/reset-password*' && location.pathname !== '/verify-email' && location.pathname !== '/__/auth/action') {
    //         setShowPopup(true);
    //         sessionStorage.setItem('promotionPopupShown', 'true');
    //         logEvent('Popup', 'Show', 'Promotion Popup');
    //     }
    // }, [user, location.pathname]);
    useEffect(() => {
        const popupShown = sessionStorage.getItem("promotionPopupShown");

        if (
            !user &&
            !popupShown &&
            !["/reset-password", "/verify-email", "/register", "/login", "/forgot-password", "/__/auth/action"].some((path) =>
                location.pathname.startsWith(path)
            )
        ) {
            setShowPopup(true);
            sessionStorage.setItem("promotionPopupShown", "true");
            logEvent("Popup", "Show", "Promotion Popup");
        }
    }, [user, location.pathname]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);


    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Błąd podczas wylogowywania:', error);
        }
    };

    const handleLinkClick = (path) => {
        navigate(path, { replace: true });
        setIsNavOpen(false); // Zwiń menu na urządzeniach mobilnych
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            const navMenu = document.querySelector('nav');
            if (isNavOpen && navMenu && !navMenu.contains(event.target)) {
                setIsNavOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isNavOpen]);

    return (
        <div className="min-h-screen bg-gradient-to-b from-[#F0F8FF] to-[#E6ECF8] text-[#1B4965] font-sans">
            <PromotionPopup showPopup={showPopup} handleClose={() => setShowPopup(false)} />

            {/* Header */}
            <header className="sticky top-0 z-40 w-full border-b bg-white">
                <div className="container mx-auto flex items-center justify-between px-4 md:px-10 max-w-screen-xl h-16">
                    {/* Logo */}
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={Logo} alt="Logo Quizonator" className="h-8 w-8" />
                        <span className="font-bold text-lg md:text-xl text-[#1B4965]">Quizonator</span>
                    </Link>


                    {/* Toggle Button for Mobile */}
                    <button
                        className="lg:hidden text-gray-600"
                        onClick={() => setIsNavOpen(!isNavOpen)}
                    >
                        {isNavOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>

                    {/* Navigation Links */}
                    <nav
                        className={`${isNavOpen ? "block" : "hidden"
                            } absolute top-16 left-0 w-full bg-white lg:static lg:flex lg:items-center lg:space-x-8 lg:w-auto shadow-md lg:shadow-none`}
                    >

                        <ul className="flex flex-col text-center lg:flex-row lg:space-x-6">
                            {[
                                { name: "Strona Główna", icon: <Home className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "Zalety", icon: <Star className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "Cennik", icon: <DollarSign className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "O nas", icon: <Info className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "Jak to dziala", icon: <HelpCircle className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "Opinie", icon: <MessageSquare className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "FAQ", icon: <BookOpen className="w-5 h-5 mr-2 lg:hidden" /> },
                                { name: "Kontakt", icon: <Phone className="w-5 h-5 mr-2 lg:hidden" /> },
                            ].map((item, index) => {
                                const path =
                                    item.name === "Strona Główna" ? "/" : `/${item.name.toLowerCase().replace(/\s+/g, "-")}`;
                                const isActive = location.pathname === path;

                                return (
                                    <li key={index} className="group">
                                        <Button
                                            onClick={() => handleLinkClick(path)}
                                            className={`block px-6 py-3 lg:p-0 text-sm font-medium transition-colors rounded-md ${isActive ? "text-[#00D1A7] font-bold" : "text-gray-700 hover:text-[#00D1A7]"
                                                }`}
                                        >
                                            {/* Ikona tylko w widoku mobilnym */}
                                            <span>{item.icon}</span>
                                            {item.name}
                                        </Button>
                                    </li>
                                );
                            })}
                        </ul>


                        {/* CTA Buttons in both mobile and desktop versions */}
                        <div className="flex flex-col lg:flex-row lg:space-x-4 my-4 lg:my-0 space-y-4 lg:space-y-0 px-6 lg:px-0">
                            {user ? (
                                <>

                                    <Button onClick={() => {
                                        handleLinkClick('/dashboard');
                                        logEvent('Navigation', 'Click', 'Dashboard');
                                    }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto">
                                        Dashboard
                                    </Button>

                                    <Button
                                        className="text-sm px-4 py-2 bg-red-500 text-white hover:bg-red-600 transition-colors w-full lg:w-auto"
                                        onClick={handleLogout}
                                    >
                                        Wyloguj się
                                    </Button>
                                </>
                            ) : (
                                <>

                                    <Button onClick={() => {
                                        handleLinkClick('/login');
                                        logEvent('Navigation', 'Click', 'Login');
                                    }}
                                        className="text-sm px-4 py-2 bg-white text-gray-500 border border-gray-500 hover:bg-gray-100 transition-colors w-full lg:w-auto">
                                        Zaloguj się
                                    </Button>


                                    <Button onClick={() => {
                                        handleLinkClick('/cennik');
                                        logEvent('Navigation', 'Click', 'Register');
                                    }} className="text-sm px-4 py-2 bg-[#00D1A7] text-white hover:bg-[#00B594] transition-colors w-full lg:w-auto">
                                        Rozpocznij
                                    </Button>

                                </>
                            )}
                        </div>
                    </nav>
                </div>
            </header>



            {/* Main Content */}
            <main>{children}</main>

            {/* Footer */}
            <footer className="bg-gradient-to-t from-[#F0F8FF] to-white border-t">
                <div className="container mx-auto py-4 md:py-6 flex flex-col md:flex-row justify-between items-center px-6 md:px-10 text-sm text-gray-600 space-y-4 md:space-y-0">
                    {/* Logo and Copyright */}
                    <div className="flex  align-middle space-x-2 text-center md:text-left md:mt-1">
                        <img src={Logo} alt="Logo Quizonator" className="h-6 w-6" />
                        <span> 2024 Quizonator. Wszelkie prawa zastrzeżone.</span>
                    </div>

                    {/* Footer Links */}
                    <nav className="flex flex-wrap justify-center md:justify-start space-x-4">
                        {["Polityka Prywatnosci", "Warunki Użytkowania", "Polityka Cookies", "Kontakt"].map((item, index) => (
                            <Link
                                key={index}
                                to={`/${item.toLowerCase().replace(/\s+/g, '-')}`}
                                className="hover:underline"
                                onClick={() => handleLinkClick(`/${item.toLowerCase().replace(/\s+/g, '-')}`)} // Poprawiono sposób wywołania handleLinkClick
                            >
                                {item}
                            </Link>
                        ))}
                    </nav>
                </div>
            </footer>

        </div>
    );
}
