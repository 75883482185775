// src/pages/ActionHandler.jsx
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ActionHandler() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const mode = query.get('mode');
        const oobCode = query.get('oobCode');

        switch (mode) {
            case 'resetPassword':
                navigate(`/reset-password?oobCode=${oobCode}`);
                break;
            case 'verifyEmail':
                navigate(`/verify-email?oobCode=${oobCode}`);
                break;
            // Dodaj inne przypadki, jeśli potrzebujesz
            default:
                navigate('/');
        }
    }, [location, navigate]);

    return null;
}

export default ActionHandler;
