import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

const DashboardTile = forwardRef(({ title, description, Icon, onClick }, ref) => {
    return (
        <motion.div
            ref={ref}
            initial={{ scale: 1 }}
            whileHover={{ scale: 1.05, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className="bg-white p-4 rounded-lg border border-gray-300 cursor-pointer"
            onClick={onClick}
        >
            <div className="flex flex-col items-start">
                <div className="flex items-center gap-2">
                    <Icon className="h-5 w-5 text-gray-700" />
                    <p className="text-gray-700 font-semibold">{title}</p>
                </div>
                <p className="text-gray-500 text-sm">{description}</p>
            </div>
        </motion.div>
    );
});

export default DashboardTile;
