import React from "react";

/**
 * Komponent Progress - pasek postępu
 * @param {Object} props
 * @param {number} props.value - Wartość postępu (w procentach, 0-100)
 * @param {string} [props.className=""] - Klasa CSS dla kontenera
 * @param {string} [props.barClassName=""] - Klasa CSS dla paska postępu
 */
export const Progress = ({ value, className = "", barClassName = "" }) => {
    const clampedValue = Math.max(0, Math.min(100, value)); // Upewnia się, że wartość jest między 0 a 100

    return (
        <div
            className={`relative w-full h-2 bg-gray-200 rounded ${className}`}
        >
            <div
                className={`absolute top-0 left-0 h-full bg-black rounded ${barClassName}`}
                style={{ width: `${clampedValue}%` }}
            ></div>
        </div>
    );
};
