// src/components/dashboard/BackButton.jsx
import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../ui/button';

const BackButton = ({ onClick }) => (
    <Button variant="ghost" onClick={onClick} className="mb-4">
        <ArrowLeft className="mr-2 h-4 w-4" /> Powrót
    </Button>
);

export default BackButton;
