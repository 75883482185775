import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Helmet } from "react-helmet";

export default function AboutUs() {
    return (
        <>
            <Helmet>
                <title>O nas - Quizonator</title>
                <meta
                    name="description"
                    content="Dowiedz się więcej o Quizonatorze - naszym zespole, misji i celu wspierania studentów w nauce dzięki inteligentnym narzędziom AI."
                />
                <meta
                    name="keywords"
                    content="Quizonator, zespół Quizonator, misja, studenci, nauka, AI"
                />
                <meta property="og:title" content="O nas - Quizonator" />
                <meta
                    property="og:description"
                    content="Poznaj nasz zespół i naszą misję wspierania studentów dzięki inteligentnemu narzędziu AI."
                />
                <meta property="og:image" content="https://quizonator.com/logo192.png" />
                <meta property="og:url" content="https://quizonator.com/o-nas" />
                <meta property="og:type" content="website" />
                <meta
                    name="twitter:description"
                    content="Poznaj zespół Quizonator i naszą misję wspierania studentów w nauce."
                />
            </Helmet>

            <section className="py-20 md:py-32 bg-gray-50">
                <div className="container max-w-6xl px-6 md:px-10 mx-auto">
                    {/* Nagłówek sekcji */}
                    <h1 className="text-4xl md:text-5xl font-extrabold text-center mb-16 text-[#1B4965]">
                        Poznaj Quizonator
                    </h1>

                    {/* Sekcja misji */}
                    <div className="mb-20">
                        <h2 className="text-3xl font-bold mb-6 text-[#1B4965] text-center">
                            Nasza misja
                        </h2>
                        <p className="text-lg text-gray-700 leading-relaxed text-center mx-auto max-w-3xl">
                            Quizonator powstał z myślą o Tobie – studencie, który pragnie więcej czasu na to, co naprawdę ważne.
                            Wiemy, jak trudno jest pogodzić naukę z pracą, pasjami i życiem osobistym, zwłaszcza gdy uczelnie zasypują Cię materiałem i wymaganiami niezwiązanymi z Twoim kierunkiem.
                            Dlatego stworzyliśmy narzędzie, które pomoże Ci przebrnąć przez te wyzwania, oszczędzając czas i redukując niepotrzebny stres.


                        </p>

                    </div>

                    {/* Sekcja zespołu */}
                    <div>
                        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1B4965]">
                            Nasz zespół
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {[
                                { name: "Dominik", role: "CEO & IT Lead" },
                                { name: "Konrad", role: "Programista" },
                                { name: "Bartosz", role: "Tester funkcjonalności" },
                                { name: "Wiktor", role: "Tester funkcjonalności" },
                                { name: "Jakub", role: "Marketing" },
                                { name: "Dominika", role: "Marketing" },
                                { name: "Dima", role: "Tester techniczny" },
                                { name: "Mateusz", role: "Designer" },
                            ].map((member, index) => (
                                <Card
                                    key={index}
                                    className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6 text-center"
                                >
                                    <CardHeader>
                                        <CardTitle className="text-xl font-semibold text-[#1B4965]">
                                            {member.name}
                                        </CardTitle>
                                    </CardHeader>
                                    <CardContent>
                                        <p className="text-lg font-medium text-gray-600">
                                            {member.role}
                                        </p>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
