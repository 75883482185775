// src/pages/RegisterPage.jsx

import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import axios from 'axios';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { logEvent } from '../analytics';
import { Helmet } from "react-helmet";

export default function RegisterPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState('');
    const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    async function onSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setMessage('');

        // Walidacja zgody na warunki
        if (!isAgreedToTerms) {
            setError('Musisz zaakceptować warunki korzystania z serwisu.');
            setIsLoading(false);
            return;
        }

        // Walidacja zgodności haseł
        if (password !== confirmPassword) {
            setError('Hasła nie są zgodne.');
            setIsLoading(false);
            return;
        }

        // Walidacja siły hasła
        if (password.length < 6) {
            setError('Hasło musi mieć co najmniej 6 znaków.');
            setIsLoading(false);
            return;
        }

        // Walidacja nazwy użytkownika
        if (username.trim().length < 3) {
            setError('Nazwa użytkownika musi mieć co najmniej 3 znaki.');
            setIsLoading(false);
            return;
        }

        try {
            // Tworzenie użytkownika w Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Pobranie tokenu ID użytkownika
            const idToken = await user.getIdToken();

            // Wysłanie danych użytkownika do backendu w celu rejestracji i wysłania e-maila weryfikacyjnego
            await axios.post(
                'https://us-central1-quizonator-a4513.cloudfunctions.net/api/register',
                {
                    uid: user.uid,
                    email,
                    username,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );

            setMessage('Rejestracja przebiegła pomyślnie. Proszę zweryfikować swój email.');
            setIsLoading(false);
            navigate('/dashboard');
            logEvent('User', 'Register', 'Nowy użytkownik');
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.error || 'Nie udało się zarejestrować użytkownika.');
            setIsLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>Rejestracja - Quizonator</title>
                <meta
                    name="description"
                    content="Zarejestruj się i uzyskaj pełen dostęp do Quizonatora - inteligentnego rozszerzenia wspierającego studentów w nauce."
                />
                <meta
                    name="keywords"
                    content="Quizonator, rejestracja, nauka, studenci, AI"
                />
                <meta property="og:title" content="Rejestracja - Quizonator" />
                <meta
                    property="og:description"
                    content="Dołącz do Quizonatora i korzystaj z inteligentnego narzędzia wspierającego studentów."
                />
                <meta property="og:image" content="https://quizonator.com/logo192.png" />
                <meta property="og:url" content="https://quizonator.com/register" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Rejestracja - Quizonator" />
                <meta
                    name="twitter:description"
                    content="Zarejestruj się i rozpocznij swoją podróż z Quizonatorem."
                />
            </Helmet>

            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
                <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                    <CardHeader className="text-center space-y-1">
                        <CardTitle className="text-3xl font-semibold text-[#1B4965]">Utwórz konto</CardTitle>
                        <CardDescription className="text-gray-600">
                            Wprowadź swoje dane, aby utworzyć konto
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="p-6 space-y-6">
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div>
                                <Label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                    Nazwa użytkownika
                                </Label>
                                <Input
                                    id="username"
                                    type="text"
                                    placeholder="Twoja nazwa użytkownika"
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder="m.kowalski@example.com"
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Hasło
                                </Label>
                                <Input
                                    id="password"
                                    type="password"
                                    placeholder="Wprowadź hasło"
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                    Potwierdź hasło
                                </Label>
                                <Input
                                    id="confirmPassword"
                                    type="password"
                                    placeholder="Powtórz hasło"
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#00D1A7]"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="terms"
                                    type="checkbox"
                                    className="h-5 w-5 text-[#00D1A7]"
                                    checked={isAgreedToTerms}
                                    onChange={(e) => setIsAgreedToTerms(e.target.checked)}
                                    required
                                />
                                <label
                                    htmlFor="terms"
                                    className="ml-2 text-sm text-gray-700"
                                >
                                    Akceptuję warunki korzystania z serwisu
                                </label>
                            </div>
                            {error && (
                                <div className="text-red-500 text-sm">
                                    {error}
                                </div>
                            )}
                            {message && (
                                <div className="text-green-500 text-sm">
                                    {message}
                                </div>
                            )}
                            <Button
                                className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? "Ładowanie..." : "Zarejestruj się"}
                            </Button>
                        </form>
                    </CardContent>
                    <CardFooter className="text-center text-sm text-gray-600">
                        Klikając "Zarejestruj się", zgadzasz się na nasze{" "}
                        <Link to="/warunki-użytkowania" className="underline text-[#2196F3] hover:text-[#1B4965]">
                            Warunki korzystania z serwisu
                        </Link>{" "}
                        i{" "}
                        <Link to="/polityka-prywatnosci" className="underline text-[#2196F3] hover:text-[#1B4965]">
                            Politykę prywatności
                        </Link>
                        .
                    </CardFooter>
                </Card>
                <p className="mt-6 text-center text-sm text-gray-600">
                    Masz już konto?{" "}
                    <Link to="/login" className="underline text-[#2196F3] hover:text-[#1B4965]">
                        Zaloguj się
                    </Link>
                </p>
            </div>
        </>
    );
}
