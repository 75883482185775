import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase"; // import konfiguracji Firebase
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Helmet } from "react-helmet";

export default function LoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const onSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setIsLoading(true);

        try {
            // Logowanie użytkownika w Firebase
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const idToken = await userCredential.user.getIdToken();

            // Zapis tokena w localStorage
            localStorage.setItem("idToken", idToken);

            setIsLoading(false);
            navigate("/dashboard"); // Przejście do dashboardu
        } catch (err) {
            setError("Nieprawidłowe dane logowania");
            setIsLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Logowanie - Quizonator</title>
                <meta
                    name="description"
                    content="Zaloguj się do Quizonatora, aby korzystać z naszego inteligentnego rozszerzenia Chrome wspomagającego studentów w nauce."
                />
                <meta
                    name="keywords"
                    content="Quizonator, logowanie, nauka, studenci, AI"
                />
                <meta property="og:title" content="Logowanie - Quizonator" />
                <meta
                    property="og:description"
                    content="Uzyskaj dostęp do Quizonatora, logując się na swoje konto."
                />
                <meta property="og:image" content="https://quizonator.com/logo192.png" />
                <meta property="og:url" content="https://quizonator.com/login" />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Logowanie - Quizonator" />
                <meta
                    name="twitter:description"
                    content="Zaloguj się i korzystaj z Quizonatora."
                />
            </Helmet>

            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
                <Card className="w-full max-w-md bg-white shadow-lg rounded-lg">
                    <CardHeader className="text-center space-y-1">
                        <CardTitle className="text-3xl font-semibold text-gray-800">Zaloguj się</CardTitle>
                        <CardDescription className="text-gray-600">
                            Wprowadź swoje dane, aby się zalogować
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="p-6 space-y-6">
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div>
                                <Label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </Label>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder="m.kowalski@example.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Hasło
                                </Label>
                                <Input
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mt-1 w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    required
                                />
                            </div>
                            {error && (
                                <p className="text-center text-sm text-red-500 bg-red-50 border border-red-400 rounded-lg p-2">
                                    {error}
                                </p>
                            )}
                            <Button
                                className="w-full py-2 bg-[#00D1A7] text-white rounded-lg hover:bg-[#00B594] disabled:opacity-50"
                                type="submit"
                                disabled={isLoading}
                            >
                                {isLoading ? "Ładowanie..." : "Zaloguj się"}
                            </Button>
                        </form>
                    </CardContent>
                    <CardFooter className="text-center text-sm text-gray-600">
                        <Link to="/forgot-password" className="underline text-blue-600 hover:text-blue-800">
                            Zapomniałeś hasła?
                        </Link>
                    </CardFooter>
                </Card>
                <p className="mt-6 text-center text-sm text-gray-600">
                    Nie masz jeszcze konta?{" "}
                    <Link to="/register" className="underline text-blue-600 hover:text-blue-800">
                        Zarejestruj się
                    </Link>
                </p>
            </div>
        </>
    );
}
