"use client";

import React from 'react';
import { AlertCircle, CheckCircle2 } from 'lucide-react';

export const Alert = ({ variant = 'default', children, className = '', ...props }) => {
    const variantStyles = {
        default: 'bg-blue-50 border-blue-400 text-blue-700',
        destructive: 'bg-red-50 border-red-400 text-red-700',
        success: 'bg-green-50 border-green-400 text-green-700',
    };

    const icon = {
        default: <AlertCircle className="h-5 w-5 text-current" />,
        destructive: <AlertCircle className="h-5 w-5 text-current" />,
        success: <CheckCircle2 className="h-5 w-5 text-current" />,
    };

    return (
        <div
            className={`flex items-start p-4 border ${variantStyles[variant]} ${className}`}
            {...props}
        >
            <div className="flex-shrink-0">
                {icon[variant]}
            </div>
            <div className="ml-3">
                {children}
            </div>
        </div>
    );
};

export const AlertTitle = ({ children, className = '', ...props }) => (
    <h4 className={`text-sm font-semibold ${className}`} {...props}>
        {children}
    </h4>
);

export const AlertDescription = ({ children, className = '', ...props }) => (
    <p className={`mt-1 text-sm ${className}`} {...props}>
        {children}
    </p>
);
